import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalCloseButton,
  Text,
} from '@chakra-ui/core';
import { Button, Input, ModalContainer, ModalContainerOptions } from 'app/components';
import * as yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { CreditTopupCard } from '../credit-topup-card';
import { numberWithCommas } from '../../../../../../utils';

type TestMessageModalProps = {
  isLoading?: boolean;
  type?: TestMessageModalType;
  credit_balance: number;
  onSubmit: (
    values: { recipients: string },
    formikHelpers: FormikHelpers<{ recipients: string }>,
  ) => void | Promise<any>;
} & ModalContainerOptions;

type TestMessageModalType = 'sms' | 'email';

export const TestMessageModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  type = 'sms',
  credit_balance,
}: TestMessageModalProps) => {
  const smsValidationSchema = yup.object().shape({
    recipients: yup.string().required('Phone numbers are required'),
  });
  const emailValidationSchema = yup.object().shape({
    recipients: yup.string().email('Invalid email format').required('Phone numbers are required'),
  });
  const typeRegister: {
    [key in TestMessageModalType]: {
      helpText: string;
      formLabel: string;
      validationSchema: any;
      inputProps: { type: string; placeholder: string };
    };
  } = {
    sms: {
      formLabel: 'Test message recipients',
      validationSchema: smsValidationSchema,
      helpText: 'Enter 10-digit phone numbers with country code, in a comma separated list.',
      inputProps: { type: 'text', placeholder: 'Phone numbers e.g 2348078657980,2348078657909' },
    },
    email: {
      formLabel: 'Test email recipient',
      helpText: 'Enter recipient email',
      validationSchema: emailValidationSchema,
      inputProps: { type: 'email', placeholder: 'test@example.com' },
    },
  };
  const { formLabel, validationSchema, helpText, inputProps } = typeRegister[type];
  const { values, touched, errors, handleChange, setFieldValue, handleSubmit } = useFormik({
    validationSchema,
    initialValues: { recipients: '' },
    onSubmit: (values, helpers) => onSubmit(values, helpers),
  });

  const recipientsLength = values.recipients.split(',').length;

  const handleClose = () => {
    setFieldValue('recipients', '');
    onClose && onClose();
  };

  const amountToPay = (recipientsLength ?? 0) * 3.65;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ModalContainer
      size="sm"
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={inputRef}
      title="Send a test message"
      titleStyleProps={{ fontSize: '1rem' }}
    >
      <ModalCloseButton size="sm" />
      <form onSubmit={handleSubmit}>
        <Box px="1.5rem">
          <FormControl isInvalid={!!touched.recipients && !!errors.recipients}>
            <FormLabel pb="0" fontSize="0.8rem">
              {formLabel}
            </FormLabel>
            <Text pb="0.5rem" color="gray.600" fontSize="0.75rem">
              {helpText}
            </Text>
            <Input
              size="sm"
              ref={inputRef}
              name="recipients"
              onChange={handleChange}
              value={values.recipients}
              {...inputProps}
            />
            <FormErrorMessage>{errors.recipients}</FormErrorMessage>
          </FormControl>
        </Box>
        {credit_balance / 100 < amountToPay && (
          <Box px="1.5rem" pt="1rem">
            <CreditTopupCard
              amount={amountToPay}
              content={`You are low on campaign credits for the number of recipients (${numberWithCommas(
                recipientsLength,
              )}) for this campaign. Please top-up to send this campaign.`}
            />
          </Box>
        )}
        <Flex
          py="1rem"
          mt="1.5rem"
          px="1.5rem"
          alignItems="center"
          borderTopWidth="1px"
          justifyContent="space-between"
        >
          <Button
            size="sm"
            width="48%"
            type="button"
            variant="outline"
            variantColor="blue"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button size="sm" width="48%" type="submit" variantColor="blue" isLoading={isLoading}>
            Send test
          </Button>
        </Flex>
      </form>
    </ModalContainer>
  );
};
