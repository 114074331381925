import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useURLState = (pathname: string) => {
  const query = useQuery();
  const history = useHistory();

  const setParams = (params?: { [key: string]: string }) => {
    if (!params) {
      history.push(pathname);
    } else {
      const urlParams = new URLSearchParams(params).toString();
      history.push(`${pathname}?${urlParams}`);
    }
  };

  return [query, setParams] as const;
};
