import styled from '@emotion/styled';

export const ListFormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 97px);
  color: #333333;

  .field-panel {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        font-size: 14px;
      }

      .actions {
        display: flex;
        flex-direction: row;
        font-size: 12px;

        .action-item {
          margin-left: 15px;
          border-bottom: 1.5px solid rgba(119, 119, 119, 0.5);
          color: #777777;
          cursor: pointer;

          :hover {
            color: #333333;
          }
        }
      }
    }

    .field-list {
      margin-top: 20px;
      height: 100%;

      .field-item {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 8px;
        margin: 10px 0;
        background-color: white;
        border: 2px solid transparent;

        :hover,
        :active,
        :focus {
          border: 2px solid rgba(119, 119, 119, 0.5);
        }
      }
    }
  }

  .form-section {
    width: 100%;
    flex: 1;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.03);
    overflow-y: scroll;

    .cover-image-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.06);
      height: 10vw;
      max-height: 15vh;
      min-height: 140px;
      min-width: 800px;
      cursor: pointer;

      :hover {
        background-color: rgba(0, 0, 0, 0.12);
      }

      .cover-image-action {
        margin-bottom: 70px;
        margin-top: 50px;
        background-color: #ffffff;
        border-radius: 1rem;
        padding: 5px 12px;
        font-size: 12px;
        height: fit-content;
      }
    }

    .form-edit-section {
      margin-top: -50px;
      width: 35rem;
      align-self: center;

      .form-heading-section {
        padding: 3rem;
        background-color: #ffffff;
        border-radius: 1rem;
        z-index: 1000;
        margin-bottom: 2rem;

        .logo-preview {
          border-radius: 8px;
          background-color: white;
          border: 2.5px dashed rgba(0, 0, 0, 0.3);
          height: 4rem;
          display: flex;
          padding: 0 1rem;
          justify-content: center;
          align-items: center;
          width: fit-content;
          cursor: pointer;
          color: #777777;
          font-size: 14px;

          :hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }

      .empty-form-section {
        padding: 2rem;
        border-radius: 1rem;
        border: 2.5px dashed rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #777777;
        font-size: 14px;
      }
    }

    .field-item {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 2rem;
      cursor: pointer;
      border-radius: 8px;
      margin: 10px 0;
      background-color: white;
      border: 2px solid transparent;
      transition: all 0.05s ease-in;

      :hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    .active {
      flex-direction: column;
      border: 2px solid rgba(0, 0, 0, 0.08);
      padding: 2rem 2rem;
      justify-content: flex-start;
      background-color: white !important;
    }

    .option-section {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.06);

      .option-content {
        width: 35rem;
        align-self: center;
        padding: 30px 10px;
        font-size: 14px;
      }
    }
  }
`;
