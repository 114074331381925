import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  PseudoBox,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { Divider, Spinner, useToast } from '@chakra-ui/core/dist';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import {
  selectAutomationActions,
  selectAutomationActionsLoading,
  selectColumnsData,
} from 'app/authenticated-app/lists/lists.selectors';
import {
  Column,
  ListAutomation,
  ListAutomationAction,
} from 'app/authenticated-app/lists/lists.types';
import { AIDraftGeneratorModal } from 'app/authenticated-app/marketing/campaigns/components/planner/ai-draft-generator';
import { SmsCampaignFormContainer } from 'app/authenticated-app/marketing/campaigns/components/sms-campaigns/container';
import { Editable } from 'app/authenticated-app/marketing/templates/components/Editable';
import { html2Text } from 'app/authenticated-app/marketing/templates/templates.utils';
import {
  listEmailSenderProfiles,
  listSMSSenderProfiles,
  saveEmailSenderProfile,
  saveSMSSenderProfile,
} from 'app/authenticated-app/settings';
import {
  EmailSenderProfileModal,
  EmailSenderProfilePayload,
} from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import {
  SMSSenderProfileModal,
  SMSSenderProfilePayload,
} from 'app/authenticated-app/settings/component/sender-profiles/sms-sender-profile-modal';
import { selectOrganisation } from 'app/authenticated-app/settings/slices';
import { SelectOptions } from 'app/authenticated-app/tables';
import {
  AdvancedSelect,
  ConfirmModal,
  EmptyState,
  Input,
  Menu,
  MenuItem,
  Select,
  Switch,
  ToastBox,
  XSmallText,
} from 'app/components';
import { LexicalEditorPlaceholder } from 'app/components/lexical-editor/components/placeholder';
import { LexicalProvider } from 'app/components/lexical-editor/components/provider';
import { ImageNode } from 'app/components/lexical-editor/nodes/image-node';
import { PlaygroundAutoLinkPlugin } from 'app/components/lexical-editor/plugins/auto-link';
import { AutoFocusPlugin } from 'app/components/lexical-editor/plugins/autofocus';
import { HtmlPlugin } from 'app/components/lexical-editor/plugins/html';
import { ImagesPlugin } from 'app/components/lexical-editor/plugins/images';
import { ListMaxIndentLevelPlugin } from 'app/components/lexical-editor/plugins/list-max-indent-level';
import { ToolbarPlugin } from 'app/components/lexical-editor/plugins/toolbar';
import { LexicalEditorStyles } from 'app/components/lexical-editor/styles';
import { richTextTheme } from 'app/components/lexical-editor/themes';
import { selectProfile } from 'app/unauthenticated-app/authentication';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'root';
import { getContent, stringSplice, textNodesUnder } from 'utils';

interface ActionItemProps {
  index: number;
  action: ListAutomationAction;
  updateAction: Function;
  deleteAction: Function;
  automationTriggerType?: string;
}

const AutomationActionItem = (props: ActionItemProps) => {
  const { action } = props;
  const [isDelayed, setIsDelayed] = useState(action.is_delayed);
  const [tempConfig, setTempConfig] = useState(action.configuration);
  const [tempDelayConfig, setTempDelayConfig] = useState(
    action.delay_configuration ?? { value: 1, unit: 'minute' },
  );
  const [characterCount, setCharacterCount] = React.useState(0);
  const [smsCount, setSmsCount] = React.useState(0);

  const editableRef = React.useRef<any>();

  const toast = useToast();
  const queryClient = useQueryClient();

  const profile = useSelector(selectProfile);
  const organisation = useSelector((state: RootState) =>
    selectOrganisation(state, profile?.organisation_id ?? ''),
  );

  const {
    isOpen: deleteDialogOpen,
    onClose: closeDeleteDialog,
    onOpen: openDeleteDialog,
  } = useDisclosure(false);

  const {
    isOpen: isAIContentGenerationModalOpen,
    onOpen: onOpenAIContentGenerationModal,
    onClose: onCloseAIContentGenerationModal,
  } = useDisclosure();

  const {
    isOpen: isSMSSenderProfileModalOpen,
    onOpen: onOpenSMSSenderProfileModal,
    onClose: onCloseSMSSenderProfileModal,
  } = useDisclosure();

  const {
    isOpen: isEmailSenderProfileModalOpen,
    onOpen: onOpenEmailSenderProfileModal,
    onClose: onCloseEmailSenderProfileModal,
  } = useDisclosure();

  const { data: sms_sender_ids } = useQuery(['sms-sender-profiles'], listSMSSenderProfiles, {
    initialData: organisation?.sender_ids ?? [],
  });

  const { data: email_sender_ids } = useQuery(['email-sender-profiles'], listEmailSenderProfiles);

  const { mutate: createSMSSenderProfileMutate, isLoading: isCreatingSMSSenderProfile } =
    useMutation<any, AxiosError, any, any>(
      (options: { payload: SMSSenderProfilePayload }) => saveSMSSenderProfile(options.payload),
      {
        onSuccess: (data, variables) => {
          const { helpers } = variables;
          queryClient.invalidateQueries(['sms-sender-profiles']);
          helpers.resetForm();
          onCloseSMSSenderProfileModal();
          handleConfigUpdate({ sender_id: data.name });
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => (
              <ToastBox
                status="success"
                onClose={onClose}
                message="SMS sender profile added successfully"
              />
            ),
          });
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
          });
        },
      },
    );

  const { mutate: createEmailSenderProfileMutate, isLoading: isCreatingEmailSenderProfile } =
    useMutation<any, AxiosError, any, any>(
      (options: { payload: EmailSenderProfilePayload }) => saveEmailSenderProfile(options.payload),
      {
        onSuccess: (data, variables) => {
          const { helpers } = variables;
          queryClient.invalidateQueries(['email-sender-profiles']);
          helpers.resetForm();
          onCloseEmailSenderProfileModal();
          handleConfigUpdate({ from_name: data.from_name });
          handleConfigUpdate({ from_email: data.from_email });
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
          });
        },
      },
    );

  const getCharacterCount = (value: string) => {
    const characterCount = value.length;
    const smsCount = Math.ceil(characterCount / 160);

    setCharacterCount(characterCount);
    setSmsCount(smsCount);
  };

  const handleEditableBlur = () => {
    const value = editableRef?.current?.innerHTML;
    getCharacterCount(value);
    handleConfigUpdate({ content: value });
  };

  useEffect(() => {
    getCharacterCount(tempConfig?.content || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempConfig?.content]);

  const handleUpdate = (payload: any) => {
    if (payload['type']) {
      payload['configuration'] = { ...tempConfig };
      if (payload['type'] === 'sms') {
        payload['configuration']['to'] = phoneColumns.length > 0 ? phoneColumns[0].id : null;
        payload['configuration']['content'] = '';
        payload['configuration']['sender_id'] = undefined;
        payload['configuration']['from_name'] = undefined;
      }
      if (payload['type'] === 'email') {
        payload['configuration']['to'] = emailColumns.length > 0 ? emailColumns[0].id : null;
        payload['configuration']['content'] = '';
        payload['configuration']['sender_id'] = undefined;
      }
      setTempConfig(payload['configuration']);
    }
    props.updateAction(payload);
  };

  const handleConfigUpdate = (payload: any) => {
    const updatedConfig = {
      ...tempConfig,
      ...payload,
    };
    setTempConfig(updatedConfig);
    debouncedCall({
      configuration: updatedConfig,
      delay_configuration: tempDelayConfig,
      is_delayed: isDelayed,
    });
  };

  const handleIsDelayedChange = (value: boolean) => {
    setIsDelayed(value);
    if (value) {
      debouncedCall({
        is_delayed: value,
        configuration: tempConfig,
        delay_configuration: tempDelayConfig,
      });
    } else {
      debouncedCall({
        is_delayed: value,
        configuration: tempConfig,
        delay_configuration: undefined,
      });
    }
  };

  const handleDelayConfigUpdate = (payload: any) => {
    const updatedDelayConfig = {
      ...tempDelayConfig,
      ...payload,
    };
    setTempDelayConfig(updatedDelayConfig);
    debouncedCall({
      delay_configuration: updatedDelayConfig,
      is_delayed: true,
      configuration: tempConfig,
    });
  };

  const getCaretPosition = () => {
    var index = 0;
    var selection = window.getSelection();
    var textNodes = textNodesUnder(editableRef.current);

    for (var i = 0; i < textNodes.length; i++) {
      var node = textNodes[i];
      var isSelectedNode = node === selection?.focusNode;

      if (isSelectedNode) {
        index += selection?.focusOffset ?? 0;
        break;
      } else {
        index += node.textContent.length;
      }
    }

    return index;
  };

  const updateConfigValue = (payload: any) => {
    if (action.type === 'sms') {
      props.updateAction({
        ...payload,
        configuration: {
          ...payload.configuration,
          content: html2Text(payload.configuration.content),
        },
      });
    } else {
      props.updateAction(payload);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(debounce(updateConfigValue, 1000), [action]);
  const columnsData = useSelector(selectColumnsData);

  // @ts-ignore
  const columns = columnsData.columns_by_id.map((i: string) => columnsData.columns[i]);

  const tags = columns.map(({ name, label }: { name: string; label: string }) => ({
    label,
    value: name,
  }));

  const emailColumns = columns.filter((i: Column) => i.type === 'EMAIL');
  const phoneColumns = columns.filter((i: Column) => i.type === 'PHONE NUMBER');

  return (
    <>
      <Box
        borderRadius="6px"
        border="1px solid #DADEE3"
        padding="1rem 1rem"
        backgroundColor="#FFFFFF"
        mb="1rem"
      >
        <Select
          size="sm"
          label="Action Type"
          marginY="1rem"
          value={action.type}
          onChange={event => handleUpdate({ type: event.target.value })}
        >
          <option value="email">Send an Email</option>
          <option value="sms">Send an SMS</option>
        </Select>
        {props.automationTriggerType !== 'schedule' && (
          <Stack mb="4">
            <Flex align="center">
              <FormLabel pb="0" fontSize="sm" fontWeight="normal" htmlFor="is-delayed">
                Delay {action.type === 'sms' ? 'SMS' : 'email'} send
              </FormLabel>
              <Switch
                size="sm"
                id="is-delayed"
                isChecked={isDelayed}
                //@ts-ignore
                onChange={e => handleIsDelayedChange(e.target.checked)}
              />
            </Flex>
            {isDelayed && (
              <Stack w="100%" isInline alignItems="center">
                <Box fontSize="12px" color="gray.500">
                  Send the {action.type === 'sms' ? 'SMS' : 'email'}
                </Box>
                <Stack flex={1} isInline alignItems="center">
                  <Box>
                    <NumberInput
                      minW="70px"
                      maxW="70px"
                      min={1}
                      step={1}
                      size="sm"
                      value={tempDelayConfig.value}
                      onChange={value => handleDelayConfigUpdate({ ...tempDelayConfig, value })}
                    >
                      <NumberInputField
                        type="number"
                        roundedTopLeft="8px"
                        roundedBottomLeft="8px"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                  <Box>
                    <Select
                      size="sm"
                      value={tempDelayConfig.unit}
                      onChange={e =>
                        handleDelayConfigUpdate({
                          ...tempDelayConfig,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value="minute">minutes</option>
                      <option value="hour">hours</option>
                      <option value="day">days</option>
                    </Select>
                  </Box>
                  <Box fontSize="12px" color="gray.500">
                    {props.index === 0 ? 'after the trigger' : 'after the previous message'}
                  </Box>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
        <Box
          fontSize="12px"
          color="#A5ABB3"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="1rem"
        >
          <Box>{action.type === 'sms' ? 'SMS' : 'Email'} details</Box>
          <Divider width="80%" />
        </Box>

        {action.type === 'sms' && (
          <AdvancedSelect
            size="sm"
            options={sms_sender_ids?.map((item: any) => ({
              value: item.name,
              label: item.name,
            }))}
            value={tempConfig?.sender_id}
            placeholder="Select sender ID"
            onChange={({ value }) => handleConfigUpdate({ sender_id: value })}
            label={
              <Stack isInline alignItems="center">
                <Text>Sender ID</Text>
                <Tooltip
                  zIndex={10000}
                  placement="right"
                  aria-label="Sender id"
                  label="A Sender ID is a quick way to let recipients of your campaign know exactly who it is from."
                >
                  <Icon size="0.75rem" name="info" color="gray.500" />
                </Tooltip>
              </Stack>
            } //@ts-ignore
            noOptionsMessage={() => (
              <EmptyState
                width="320px"
                heading="Add an SMS sender ID"
                subheading="Standout in your customer's inbox with unique sender ID for your organisation."
              >
                <Button size="sm" variantColor="blue" onClick={onOpenSMSSenderProfileModal}>
                  Add SMS Sender ID
                </Button>
              </EmptyState>
            )}
          />
        )}

        {action.type === 'email' && (
          <AdvancedSelect
            size="sm"
            options={email_sender_ids?.map((item: any) => ({
              value: item.from_email,
              label: `${item.from_email} - ${item.from_name}`,
            }))}
            value={tempConfig?.sender_id}
            placeholder="Select a sender ID"
            onChange={({ value }) => {
              const { from_name } =
                email_sender_ids?.find((item: any) => item.from_email === value) ?? {};
              handleConfigUpdate({ sender_id: value, from_name });
            }}
            label={
              <Stack isInline alignItems="center">
                <Text>Sender ID</Text>
                <Tooltip
                  zIndex={10000}
                  placement="right"
                  aria-label="Sender profile"
                  label="A Sender profile is a quick way to let recipients of your campaign know exactly who it is from."
                >
                  <Icon size="0.75rem" name="info" color="gray.500" />
                </Tooltip>
              </Stack>
            } //@ts-ignore
            noOptionsMessage={() => (
              <EmptyState
                width="320px"
                heading="Add an email sender profile"
                subheading="Standout in your customer's inbox with unique sender profile for your organisation."
              >
                <Button size="sm" variantColor="blue" onClick={onOpenEmailSenderProfileModal}>
                  Add email sender profile
                </Button>
              </EmptyState>
            )}
          />
        )}

        {action.type === 'sms' && (
          <>
            <Select
              size="sm"
              label="To"
              marginY="1rem"
              placeholder="Select a Phone Number column"
              value={tempConfig?.to}
              onChange={event => handleConfigUpdate({ to: event.target.value })}
            >
              {phoneColumns.map((i: Column) => (
                <option key={i.id} value={i.id}>
                  {i.label}
                </option>
              ))}
            </Select>
          </>
        )}

        {action.type === 'email' && (
          <>
            <Select
              size="sm"
              label="To"
              marginY="1rem"
              placeholder="Select an Email column"
              value={tempConfig?.to || undefined}
              onChange={event => handleConfigUpdate({ to: event.target.value })}
            >
              {emailColumns.map((i: Column) => (
                <option key={i.id} value={i.id}>
                  {i.label}
                </option>
              ))}
            </Select>

            <Box marginY="1rem">
              <Input
                label="Subject"
                size="sm"
                fontSize="14px"
                onChange={(event: any) => {
                  handleConfigUpdate({
                    subject: event.target.value,
                  });
                }}
                value={tempConfig?.subject || ''}
              />
            </Box>
          </>
        )}

        {action.type === 'sms' ? (
          <SmsCampaignFormContainer>
            <Box mb="0.5rem" p="0.5rem" borderWidth="1px" borderRadius="8px" borderColor="#e2e8f0">
              <Flex flexDirection="column">
                <Flex justifyContent="flex-end">
                  <Menu
                    options={tags ?? []}
                    menuListProps={{
                      minW: '80px',
                      width: 'auto',
                      height: '150px',
                      overflowY: 'auto',
                      placement: 'auto-end',
                    }}
                    menuButtonProps={{
                      px: '0',
                      minW: '0',
                      children: (
                        <Tooltip label="Tags" placement="top" aria-label="Tags" shouldWrapChildren>
                          <Button
                            size="xs"
                            type="button"
                            variant="ghost"
                            color="gray.500"
                            isDisabled={!tags?.length}
                            //@ts-ignore
                            leftIcon="marketing-campaign-design-tags"
                          >
                            Personalize
                          </Button>
                        </Tooltip>
                      ),
                    }}
                    renderItem={({ label, value }: SelectOptions) => (
                      <MenuItem
                        fontSize="0.875rem"
                        onClick={() => {
                          const caretPosition = getCaretPosition();
                          const string = stringSplice(
                            html2Text(tempConfig?.content ?? ''),
                            caretPosition,
                            ` {{${value}}} `,
                          );
                          handleConfigUpdate({
                            content: string,
                          });
                        }}
                      >
                        <XSmallText>{label}</XSmallText>
                      </MenuItem>
                    )}
                  />
                </Flex>
                <Box pb="0.5rem" flex={1}>
                  <Editable
                    innerRef={editableRef}
                    placeholder="Write here..."
                    onBlur={handleEditableBlur}
                    onChange={(value: string) => {
                      handleConfigUpdate({
                        content: getContent(value),
                      });
                    }}
                    value={getContent(tempConfig?.content || '')}
                  />
                </Box>
              </Flex>

              <Stack isInline alignItems="center" pt="0.5rem" borderTopWidth="1px">
                <Box>
                  <Tooltip
                    placement="top"
                    aria-label="add"
                    label="AI generated content for your SMS campaign."
                  >
                    <Button
                      size="xs"
                      zIndex={0}
                      rounded="8px"
                      color="white"
                      //@ts-ignore
                      leftIcon="magic-wand"
                      variant="outline"
                      position="relative"
                      className="ai-button"
                      aria-label="AI generated content"
                      onClick={onOpenAIContentGenerationModal}
                    >
                      Generate text with AI
                    </Button>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
          </SmsCampaignFormContainer>
        ) : (
          <>
            <LexicalEditorStyles mb="0.5rem" w="100%" position="relative">
              <div className="editor-container">
                <ToolbarPlugin tags={tags} />
                <div className="editor-inner">
                  <RichTextPlugin
                    contentEditable={<ContentEditable className="editor-input" />}
                    placeholder={<LexicalEditorPlaceholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <HistoryPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <ImagesPlugin />
                  <AutoFocusPlugin />
                  <HtmlPlugin
                    initialValue={getContent(tempConfig?.content)}
                    onChange={(value: string) => {
                      handleConfigUpdate({
                        content: value,
                      });
                    }}
                  />
                  <PlaygroundAutoLinkPlugin />
                  <ListMaxIndentLevelPlugin maxDepth={7} />
                </div>
              </div>
            </LexicalEditorStyles>
          </>
        )}
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <Tooltip aria-label="delete action" label="Delete Action" hasArrow>
              <IconButton
                size="xs"
                variantColor="red"
                variant="outline"
                aria-label="delete action"
                // @ts-ignore
                icon="trash"
                onClick={openDeleteDialog}
              />
            </Tooltip>
          </Flex>

          {action.type === 'sms' && (
            <Flex alignItems="center" justifyContent="flex-end">
              <PseudoBox as="span" fontSize="0.7rem">
                {characterCount}
              </PseudoBox>
              <Divider mx="0.4rem" height="0.75rem" orientation="vertical" />
              <PseudoBox as="span" fontSize="0.7rem">
                {smsCount} SMS
              </PseudoBox>
            </Flex>
          )}
        </Flex>
      </Box>
      {deleteDialogOpen && (
        <ConfirmModal
          title="Delete AutomationAction"
          isOpen={deleteDialogOpen}
          onConfirm={() => props.deleteAction()}
          onClose={closeDeleteDialog}
        />
      )}

      {isSMSSenderProfileModalOpen && (
        <SMSSenderProfileModal
          isOpen={isSMSSenderProfileModalOpen}
          onClose={onCloseSMSSenderProfileModal}
          isLoading={isCreatingSMSSenderProfile}
          onSubmit={(payload, helpers) => createSMSSenderProfileMutate({ payload, helpers })}
        />
      )}

      {isEmailSenderProfileModalOpen && (
        <EmailSenderProfileModal
          isOpen={isEmailSenderProfileModalOpen}
          isLoading={isCreatingEmailSenderProfile}
          onClose={onCloseEmailSenderProfileModal}
          onSubmit={(payload, helpers) => createEmailSenderProfileMutate({ payload, helpers })}
        />
      )}

      {isAIContentGenerationModalOpen && (
        <AIDraftGeneratorModal
          isOpen={isAIContentGenerationModalOpen}
          onClose={onCloseAIContentGenerationModal}
          onUseGeneratedText={content => {
            handleConfigUpdate({
              content,
            });
          }}
        />
      )}
    </>
  );
};

interface Props {
  automation: ListAutomation | null;
  addAutomationAction: Function;
  updateAutomationAction: Function;
  deleteAutomationAction: Function;
}

export const AutomationActionsSection = (props: Props) => {
  const { automation } = props;

  const actionsLoading: boolean = useSelector(selectAutomationActionsLoading);
  const actions: ListAutomationAction[] = useSelector(selectAutomationActions);

  const toast = useToast();

  const addAction = async () => {
    try {
      await props.addAutomationAction(automation?.id);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="Unable to create action, please try again."
          />
        ),
      });
    }
  };

  const deleteAction = (actionID: string) => {
    props.deleteAutomationAction(actionID);
  };

  return (
    <>
      <Box
        my="1rem"
        mb="0.5rem"
        fontWeight="500"
        fontSize="12px"
        display="flex"
        alignItems="center"
      >
        <Box mr="0.5rem">ACTIONS</Box>
        {actionsLoading && <Spinner size="xs" />}
      </Box>

      {actions.length === 0 && !actionsLoading && (
        <Box
          borderRadius="6px"
          border="1px solid #DADEE3"
          padding="1rem 1rem"
          backgroundColor="#FFFFFF"
        >
          <Box fontSize="14px" color="#A5ABB3">
            No actions configured
          </Box>
        </Box>
      )}

      {actions.map((i: ListAutomationAction, index: number) => (
        <LexicalProvider
          key={i.id}
          editorConfig={{
            namespace: `emailEditor-${index}`,
            theme: richTextTheme,
            onError(error: any) {
              throw error;
            },
            nodes: [
              HeadingNode,
              ListNode,
              ListItemNode,
              QuoteNode,
              AutoLinkNode,
              LinkNode,
              ImageNode,
            ],
          }}
        >
          <AutomationActionItem
            key={i.id}
            index={index}
            action={i}
            automationTriggerType={automation?.trigger_type}
            updateAction={(payload: any) => props.updateAutomationAction(i.id, payload)}
            deleteAction={() => deleteAction(i.id)}
          />
        </LexicalProvider>
      ))}

      <Box width="100%" display="flex" my="1.5rem" justifyContent="center">
        <Button
          color="#026ae8"
          fontSize="14px"
          fontWeight="400"
          variant="link"
          onClick={addAction}
          isDisabled={actionsLoading}
        >
          <Icon name="inbox-plus-circle" mr="5px" />
          <Box>Add an action</Box>
        </Button>
      </Box>
    </>
  );
};
