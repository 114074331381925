// @ts-nocheck
import React from 'react';
import { ListGrid } from '../ListViews';
import { ConfirmModal } from 'app/components';
import { ListFormView } from '../ListViews/Form';
import { useSelector } from 'react-redux';
import { selectViews } from 'app/authenticated-app/lists/lists.selectors';
import { ListView } from 'app/authenticated-app/lists.types';
import { useParams } from 'react-router-dom';

export const ListBody = (props: any) => {
  const {
    clearRowsDialogOpen,
    closeClearRowsDialog,
    handleClearRows,
    deleteRowsDialogOpen,
    closeDeleteRowsDialog,
    handleDeleteRows,
  } = props;

  const { type, viewId } = useParams<{ type: 'grid' | 'form'; viewId?: string }>();

  const views: ListView[] = useSelector(selectViews);
  const activeView: ListView = views.find((i: ListView) => i.id === viewId);

  return (
    <>
      {type === 'grid' && (
        <>
          <ListGrid {...props} />
          <ConfirmModal
            isOpen={clearRowsDialogOpen}
            onClose={closeClearRowsDialog}
            title="Clear rows"
            description="All cells currently occupied by the selected rows would be completely emptied"
            onConfirm={handleClearRows}
          />
          <ConfirmModal
            isOpen={deleteRowsDialogOpen}
            onClose={closeDeleteRowsDialog}
            title="Delete rows"
            description="All selected rows would be permanently deleted"
            onConfirm={handleDeleteRows}
          />
        </>
      )}

      {type === 'form' && <ListFormView {...props} form={activeView?.form} />}
    </>
  );
};
