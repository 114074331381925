import { css, Global } from '@emotion/react';
import React from 'react';

export const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        font-family: Averta, sans-serif !important;
      }
      html {
        scroll-behavior: smooth;
      }
      .lexical-rich-text-dropdown {
        & button.item i {
          opacity: 0.6;
        }

        & button.item.dropdown-item-active {
          background-color: rgba(223, 232, 250, 0.3);
        }

        & button.item.dropdown-item-active i {
          opacity: 1;
        }
      }

      .lexical-rich-text-dropdown .item {
        padding: 8px;
        color: #050505;
        cursor: pointer;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-content: center;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: space-between;
        background-color: #fff;
        border: 0;
        width: 100%;

        & .icon.paragraph {
          background-repeat: no-repeat;
          background-image: url(/images/icons/text-paragraph.svg);
        }

        & .icon.large-heading {
          background-repeat: no-repeat;
          background-image: url('/images/icons/type-h1.svg');
        }

        & .icon.h1 {
          background-repeat: no-repeat;
          background-image: url('/images/icons/type-h1.svg');
        }

        & .icon.small-heading {
          background-repeat: no-repeat;
          background-image: url('/images/icons/type-h2.svg');
        }

        & .icon.h2 {
          background-repeat: no-repeat;
          background-image: url('/images/icons/type-h2.svg');
        }

        & .icon.bullet-list {
          background-repeat: no-repeat;
          background-image: url('/images/icons/list-ul.svg');
        }

        & .icon.ul {
          background-repeat: no-repeat;
          background-image: url('/images/icons/list-ul.svg');
        }

        & .icon.numbered-list {
          background-repeat: no-repeat;
          background-image: url('/images/icons/list-ol.svg');
        }

        & .icon.ol {
          background-repeat: no-repeat;
          background-image: url('/images/icons/list-ol.svg');
        }

        & .icon.quote {
          background-repeat: no-repeat;
          background-image: url('/images/icons/chat-square-quote.svg');
        }

        & .icon.code {
          background-repeat: no-repeat;
          background-image: url('/images/icons/code.svg');
        }
      }

      .lexical-rich-text-dropdown .item .active {
        display: flex;
        width: 20px;
        height: 20px;
        background-size: contain;
      }

      .lexical-rich-text-dropdown .item:first-child {
        margin-top: 8px;
      }

      .lexical-rich-text-dropdown .item:last-child {
        margin-bottom: 8px;
      }

      .lexical-rich-text-dropdown .item:hover {
        background-color: #eee;
      }

      .lexical-rich-text-dropdown .item .text {
        display: flex;
        line-height: 20px;
        flex-grow: 1;
      }

      .lexical-rich-text-dropdown .item .icon {
        display: flex;
        width: 16px;
        height: 16px;
        user-select: none;
        margin-right: 12px;
        line-height: 16px;
        background-size: contain;
      }

      .lexical-rich-text-link-editor {
        position: absolute;
        z-index: 1401;
        top: -10000px;
        left: -10000px;
        margin-top: -6px;
        max-width: 300px;
        width: 100%;
        opacity: 0;
        background-color: #fff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        transition: opacity 0.5s;
      }

      .lexical-rich-text-link-editor .link-input {
        display: block;
        width: calc(100% - 24px);
        box-sizing: border-box;
        margin: 8px 12px;
        padding: 8px 12px;
        border-radius: 15px;
        background-color: #eee;
        font-size: 15px;
        color: rgb(5, 5, 5);
        border: 0;
        outline: 0;
        position: relative;
        font-family: inherit;
      }

      .lexical-rich-text-link-editor div.link-edit {
        background-image: url(/images/icons/pencil-fill.svg);
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        vertical-align: -0.25em;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }

      .lexical-rich-text-link-editor .link-input a {
        color: rgb(33, 111, 219);
        text-decoration: none;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 30px;
        text-overflow: ellipsis;
      }

      .lexical-rich-text-link-editor .link-input a:hover {
        text-decoration: underline;
      }

      .lexical-rich-text-link-editor .button {
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 6px;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 2px;
      }

      .lexical-rich-text-link-editor .button.hovered {
        width: 20px;
        height: 20px;
        display: inline-block;
        background-color: #eee;
      }

      .lexical-rich-text-link-editor .button i {
        background-size: contain;
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: -0.25em;
      }
    `}
  />
);
