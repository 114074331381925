import {
  Box,
  Flex,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { selectListsById, selectSegments } from 'app/authenticated-app/lists';
import { listEmailSenderProfiles } from 'app/authenticated-app/settings';
import { EmailSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { Button, InfoModal, Menu, MenuItem, SmallText, ToastBox } from 'app/components';
import { AxiosError } from 'axios';
import { useLoading } from 'hooks';
import { useFlag } from 'hooks/use-flag';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'root';
import { sendAmplitudeData } from 'utils';
import usePermission from 'utils/usePermission';
import { addCampaign, fetchCampaign, saveCampaignDraft } from './campaigns.reducer';
import {
  CAMPAIGN_LIST_LIMIT,
  deleteCampaignItem,
  filterCampaigns,
  getEmailDomains,
  searchCampaigns,
} from './campaigns.service';
import {
  CampaignComponentProps,
  CampaignData,
  CampaignPayload,
  CampaignStates,
  CampaignTypes,
  EmailDomain,
} from './campaigns.types';
import { CampaignContent } from './components';

export function CampaignsComponent(props: CampaignComponentProps) {
  const { history } = props;

  const [page, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [end, setEnd] = useState(CAMPAIGN_LIST_LIMIT);
  const [activeState, setActiveState] = useState<CampaignStates>();
  const [activeType, setActiveType] = useState<CampaignTypes>();
  const [isCreatingEmailCampaign, setIsCreatingEmailCampaign] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const lists = useSelector(selectListsById);
  const segments = useSelector(selectSegments);
  const { dispatch: dispatchLoading, loading } = useLoading();
  const {
    isOpen: isNoListInfoModalOpen,
    onClose: onCloseNoListInfoModal,
    onOpen: onOpenNoListInfoModal,
  } = useDisclosure();
  const {
    isOpen: isNoEmailDomainModalOpen,
    onClose: onCloseNoEmailDomainModal,
    onOpen: onOpenNoEmailDomainModal,
  } = useDisclosure();

  const isNewEmailCampaignUIEnabled = useFlag('new_email_campaign_ui');

  const { data: emailDomains } = useQuery<EmailDomain[]>('email-domains', getEmailDomains);

  const { data: emailSenderProfiles } = useQuery<EmailSenderProfilePayload[] | undefined>(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  async function handleFilterCampaigns({
    page,
    type,
    state,
  }: {
    page: number;
    type?: CampaignTypes;
    state?: CampaignStates;
  }) {
    try {
      const { data } = await filterCampaigns({ page, type, state });
      return data;
    } catch (error) {}
  }

  function handleSearchCampaigns(query: string) {
    setSearchQuery(query);
  }

  function handlePagination(pageNumber: number) {
    if (pageNumber > page) {
      setStart(start + CAMPAIGN_LIST_LIMIT);
      setEnd(end + CAMPAIGN_LIST_LIMIT);
    } else {
      setStart(start - CAMPAIGN_LIST_LIMIT);
      setEnd(end - CAMPAIGN_LIST_LIMIT);
    }
    setPage(pageNumber);
  }

  function handleTabChange(tab: CampaignTypes) {
    setActiveType(tab);
    setPage(1);
    setStart(1);
    setEnd(CAMPAIGN_LIST_LIMIT);
  }

  function handleStateChange(filter: CampaignStates | 'All') {
    const state = filter !== 'All' ? filter : undefined;

    setActiveState(state);
    setPage(1);
    setStart(1);
    setEnd(CAMPAIGN_LIST_LIMIT);
  }

  function handleCreateOneTimeSMSCampaign() {
    if (!lists.length && !segments.length) {
      onOpenNoListInfoModal();
    } else {
      history.push('/s/marketing/sms-campaigns/new/one-time-campaign');
    }
  }

  const { isLoading: isDeletingCampaign, mutate: mutateDeleteCampaign } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((campaign: CampaignData) => deleteCampaignItem({ id: campaign.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries('campaigns');
      sendAmplitudeData('campaignDeleted');
      history.push('/s/marketing/campaigns');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign deleted successfully" />
        ),
      });
    },
    onError: error => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  async function handleCreateEmailDraftEmailCampaign() {
    if (isNewEmailCampaignUIEnabled) {
      history.push('/s/marketing/email-campaigns/new/templates');
    } else {
      try {
        const payload: Partial<CampaignPayload> = {
          name: 'New campaign',
          state: 'pristine',
          type: 'email',
          via: 'email',
          from_name: emailSenderProfiles?.find(item => item.is_default)?.from_name,
          sender_id: emailSenderProfiles?.find(item => item.is_default)?.from_email ?? '',
          reply_to: emailSenderProfiles?.find(item => item.is_default)?.reply_to ?? '',
        };
        setIsCreatingEmailCampaign(true);
        const { campaign }: any = await dispatch(addCampaign(payload));
        setIsCreatingEmailCampaign(false);
        sendAmplitudeData('emailCampaignSavedAsDraft', { data: payload });
        history.push(`/s/marketing/email-campaigns/${campaign.id}`);
      } catch (error) {
        setIsCreatingEmailCampaign(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  }

  async function handleCreateEmailCampaign() {
    if (!lists.length && !segments.length) {
      onOpenNoListInfoModal();
    } else if (
      !emailDomains?.length ||
      !emailDomains?.filter(domain => domain.status === 'verified').length
    ) {
      onOpenNoEmailDomainModal();
    } else {
      handleCreateEmailDraftEmailCampaign();
    }
  }

  const handleDuplicateCampaign = async (campaign: CampaignData) => {
    try {
      dispatchLoading({ type: 'LOADING_STARTED' });
      //@ts-ignore
      const { campaign: campaignData } = await dispatch(fetchCampaign(campaign.id));
      const data = await dispatch(
        saveCampaignDraft({
          ...campaignData,
          state: 'draft',
          schedule_end: undefined,
          schedule_start: undefined,
          send_time: undefined,
          timezone: undefined,
        }),
      );
      dispatchLoading({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign duplicated successfully" />
        ),
      });
      //@ts-ignore
      history.push(`/s/marketing/${campaign.type}-campaigns/${data.campaign.id}?duplicate=true`);
    } catch (error: any) {
      dispatchLoading({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const { data, isFetching: isFetchingCampaigns } = useQuery(
    ['campaigns', page, activeState, activeType],
    () => handleFilterCampaigns({ page, type: activeType, state: activeState }),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const { data: searchResults, isLoading: isSearchingCampaigns } = useQuery(
    ['campaigns-search', searchQuery],
    () =>
      searchCampaigns({ query: searchQuery || undefined, state: activeState, type: activeType }),
    { enabled: !!searchQuery },
  );

  const { profile } = useSelector((state: RootState) => state.auth);
  const permissions = profile && profile.permissions;
  const requiresCampaignPermission = usePermission('campaign.create', permissions || []);

  return (
    <>
      <Flex pb="2.5rem" alignItems="center" justifyContent="space-between">
        <Box>
          <Heading as="h4" pb="0.2rem" fontSize="1.2rem" fontWeight={600} color="black">
            Campaigns
          </Heading>
          <Text maxW="500px" color="#4f4f4f" fontSize="0.875rem">
            Easily design and send targeted messages to reach your customers throughout their
            lifecycle.
          </Text>
        </Box>
        {requiresCampaignPermission && (
          <Menu
            menuListProps={{
              placement: 'auto-end',
            }}
            renderItem={(option, index) => <MenuItem key={`${index}`} {...option} />}
            menuButtonProps={{
              as: Button,
              size: 'sm',
              //@ts-ignore
              variantColor: 'blue',
              _focus: { boxShadow: 'none' },
              children: (
                <Stack isInline alignItems="center">
                  <SmallText fontWeight="bold">Create campaign</SmallText>
                  <Icon size="1rem" name="chevron-down" />
                </Stack>
              ),
            }}
            options={[
              {
                children: (
                  <Stack isInline alignItems="center">
                    <Icon name="smslogs" />
                    <SmallText>SMS Campaign</SmallText>
                  </Stack>
                ),
                onClick: handleCreateOneTimeSMSCampaign,
              },
              {
                children: (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Stack isInline alignItems="center">
                      <Icon name="inbox-mail" />
                      <SmallText>Email Campaign</SmallText>
                    </Stack>
                    {isCreatingEmailCampaign && <Spinner size="sm" color="blue.500" />}
                  </Flex>
                ),
                onClick: handleCreateEmailCampaign,
              },
            ]}
          />
        )}
      </Flex>
      <CampaignContent
        end={end}
        page={page}
        start={start}
        selectedTab={activeType}
        selectedState={activeState}
        onTabChange={handleTabChange}
        onPagination={handlePagination}
        onSearch={handleSearchCampaigns}
        onStateChange={handleStateChange}
        total_count={data?.meta?.count_total}
        isDeletingCampaign={isDeletingCampaign}
        onDeleteCampaign={mutateDeleteCampaign}
        isDuplicatingCampaign={loading === 'pending'}
        onDuplicateCampaign={handleDuplicateCampaign}
        typeOptions={[
          { label: 'All', value: undefined },
          { label: 'SMS', value: 'sms' },
          { label: 'Email', value: 'email' },
        ]}
        stateOptions={[
          { label: 'All', value: undefined },
          { label: 'Scheduled', value: 'scheduled' },
          { label: 'Sent', value: 'sent' },
          { label: 'Draft', value: 'draft' },
          { label: 'Paused', value: 'paused' },
          { label: 'Insufficient Credit', value: 'insufficient credit' },
          { label: 'Unknown Error', value: 'unknown error' },
          { label: 'No Phone Column', value: 'no phone column' },
        ]}
        campaignsList={searchResults ?? data?.campaigns}
        isLoading={isFetchingCampaigns || isSearchingCampaigns}
      />
      {isNoListInfoModalOpen && (
        <InfoModal
          isOpen={isNoListInfoModalOpen}
          onClose={onCloseNoListInfoModal}
          heading="You have no lists created"
          caption="Create a list to get started with sending campaigns!"
          buttonProps={{
            children: 'Create list',
            onClick: () => history.push('/s/lists/lists/new'),
          }}
        />
      )}
      {isNoEmailDomainModalOpen && (
        <InfoModal
          isOpen={isNoEmailDomainModalOpen}
          onClose={onCloseNoEmailDomainModal}
          heading="Authenticate your organization's email domain"
          caption="Authenticate your organization domains and start sending email campaigns with your organisation emails."
          buttonProps={{
            children: 'Authenticate domain',
            onClick: () => history.push('/s/settings/organisation/sending-domains'),
          }}
        />
      )}
    </>
  );
}
