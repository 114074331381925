import {
  Badge,
  Box,
  Flex,
  IconButton,
  Image,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import {
  BodyText,
  Button,
  ConfirmModal,
  FullPageSpinner,
  InfoModal,
  Subtitle,
  TableDropdown,
  ToastBox,
} from 'app/components';
import { selectUserID } from 'app/unauthenticated-app/authentication';
import { AxiosError } from 'axios';
import { icons } from 'feather-icons';
import { useLoading } from 'hooks';
import groupBy from 'lodash/groupBy';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { sendAmplitudeData } from 'utils';
import { track } from 'utils/segment';
import { editCampaign, fetchEmailCampaignReport, saveCampaignDraft } from '.';
import {
  deleteCampaignItem,
  generateMarketingReport,
  getEmailDomains,
  saveCampaignItemState,
} from './campaigns.service';
import {
  CampaignData,
  CampaignPayload,
  EmailCampaignAnalyticsData,
  EmailDomain,
} from './campaigns.types';
import { campaignStateLabel, campaignStatusColors } from './campaigns.utils';
import { ClicksReportDownloadModal } from './components/analytics/ClicksReportDownloadModal';
import { EmailAnalytics } from './components/analytics/EmailAnalytics';
import { ShareCampaignAnalyticsModal } from './components/analytics/ShareCampaignAnalyticsModal';
import { ComingSoonModal } from 'app/components/ComingSoonModal';
import { addCampaign } from './campaigns.reducer';
import { EmailSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { listEmailSenderProfiles } from 'app/authenticated-app/settings';

export const EmailCampaignAnalyticsUI = () => {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const user_id = useSelector(selectUserID);

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { dispatch: dispatchLoading, loading } = useLoading();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isCreatingEmailCampaign, setIsCreatingEmailCampaign] = useState(false);

  const { data: emailDomains } = useQuery<EmailDomain[]>('email-domains', getEmailDomains);

  const { data: emailSenderProfiles } = useQuery<EmailSenderProfilePayload[] | undefined>(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  const { data, isLoading } = useQuery<EmailCampaignAnalyticsData>(
    ['email-campaign-report', id],
    () => fetchEmailCampaignReport(id),
  );

  const {
    isOpen: deleteCampaignModalIsOpen,
    onOpen: onOpenDeleteCampaignModal,
    onClose: onCloseDeleteCampaignModal,
  } = useDisclosure();

  const {
    isOpen: isOptionsDropdownOpen,
    onOpen: onOpenOptionsDropdown,
    onClose: onCloseOptionsDropdown,
  } = useDisclosure();

  const {
    isOpen: duplicateCampaignModalIsOpen,
    onOpen: onOpenDuplicateCampaignModal,
    onClose: onCloseDuplicateCampaignModal,
  } = useDisclosure();

  const {
    isOpen: isExportSuccessToastOpen,
    onOpen: onOpenExportSuccessToast,
    onClose: onCloseExportSuccessToast,
  } = useDisclosure();

  const {
    isOpen: isReportsDownloadModalOpen,
    onClose: onCloseReportsDownloadModal,
    onOpen: onOpenReportsDownloadModal,
  } = useDisclosure();

  const {
    isOpen: isShareAnalyticsModalOpen,
    onClose: onCloseShareAnalyticsModal,
    onOpen: onOpenShareAnalyticsModal,
  } = useDisclosure();

  const {
    isOpen: isComingSoonModalOpen,
    onOpen: onOpenComingSoonModal,
    onClose: onCloseComingSoonModal,
  } = useDisclosure();

  const {
    isOpen: isNoEmailDomainModalOpen,
    onClose: onCloseNoEmailDomainModal,
    onOpen: onOpenNoEmailDomainModal,
  } = useDisclosure();

  const { isLoading: isDeletingCampaign, mutate: mutateDeleteCampaign } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((campaign: CampaignData) => deleteCampaignItem({ id: campaign.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries('campaigns');
      sendAmplitudeData('campaignDeleted');
      history.push('/s/marketing/campaigns');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign deleted successfully" />
        ),
      });
    },
    onError: error => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  const { mutate: mutateCampaignState } = useMutation<any, AxiosError, any, any>(
    (payload: { campaign: CampaignData; state: CampaignData['state'] }) =>
      saveCampaignItemState({ id: payload.campaign.id, state: payload.state }),
    {
      onSuccess: ({ data }) => {
        const campaign = data.campaign;

        dispatch(editCampaign({ campaign }));
        queryClient.invalidateQueries('campaigns');
        queryClient.invalidateQueries(['email-campaign-report', id]);
      },
      onError: (error, _, context) => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const { campaign, reports, most_clicked_users, most_opened_users } = data ?? {};

  const handleCreateEmailCampaign = async () => {
    try {
      const payload: Partial<CampaignPayload> = {
        name: 'New campaign',
        state: 'pristine',
        type: 'email',
        via: 'email',
        from_name: emailSenderProfiles?.find(item => item.is_default)?.from_name,
        sender_id: emailSenderProfiles?.find(item => item.is_default)?.from_email ?? '',
        reply_to: emailSenderProfiles?.find(item => item.is_default)?.reply_to ?? '',
      };
      setIsCreatingEmailCampaign(true);
      const { campaign }: any = await dispatch(addCampaign(payload));
      setIsCreatingEmailCampaign(false);
      sendAmplitudeData('emailCampaignSavedAsDraft', { data: payload });
      history.push(`/s/marketing/email-campaigns/${campaign.id}`);
    } catch (error: any) {
      setIsCreatingEmailCampaign(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  async function handlCreateCampaignClick() {
    if (
      !emailDomains?.length ||
      !emailDomains?.filter(domain => domain.status === 'verified').length
    ) {
      onOpenNoEmailDomainModal();
    } else {
      handleCreateEmailCampaign();
    }
  }

  const handleCampaignStateChange = useCallback(
    (campaign: CampaignData, state: CampaignData['state']) => {
      mutateCampaignState({ campaign, state });
    },
    [mutateCampaignState],
  );

  const handleDuplicateCampaign = async () => {
    try {
      const payload = {
        ...(campaign ?? {}),
        state: 'draft',
        type: 'email',
        schedule_end: undefined,
        schedule_start: undefined,
        send_time: undefined,
        timezone: undefined,
      } as CampaignData;
      dispatchLoading({ type: 'LOADING_STARTED' });
      const data = await dispatch(saveCampaignDraft(payload));
      dispatchLoading({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign duplicated successfully" />
        ),
      });
      track('Email Campaign Draft', payload);
      //@ts-ignore
      history.push(`/s/marketing/email-campaigns/${data.campaign.id}?duplicate=true`);
    } catch (error: any) {
      dispatchLoading({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleGenerateEmailClickReport = async () => {
    try {
      setIsGeneratingReport(true);
      await generateMarketingReport({
        via: 'email',
        campaign_id: id,
        user_id: user_id ?? '',
        type: 'marketing.campaign.clicks',
      });
      setIsGeneratingReport(false);
      onCloseReportsDownloadModal();
      onOpenExportSuccessToast();
      track('Downloaded Clicks Report', {
        name: campaign?.name,
        type: campaign?.type,
      });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Report generated successfully" />
        ),
      });
    } catch (error: any) {
      setIsGeneratingReport(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  function getTableActions(payload: CampaignData) {
    const { state, schedule_start } = payload;
    if (state === 'paused' || state === 'stopped') {
      return [
        {
          icon: 'play',
          label: 'Start campaign',
          onClick: (data: CampaignData) => handleCampaignStateChange(data, 'started'),
        },
        {
          icon: 'copy',
          label: 'Duplicate campaign',
          onClick: onOpenDuplicateCampaignModal,
        },
        {
          icon: 'delete',
          label: 'Delete campaign',
          onClick: onOpenDeleteCampaignModal,
        },
      ];
    }

    if (state === 'started' && !!schedule_start) {
      return [
        {
          icon: 'pause',
          label: 'Pause campaign',
          onClick: (data: CampaignData) => {
            const state = data.state === 'started' ? 'paused' : 'started';
            handleCampaignStateChange(data, state);
          },
        },
        {
          icon: 'stop',
          label: 'Stop campaign',
          onClick: (data: CampaignData) => handleCampaignStateChange(data, 'stopped'),
        },
        {
          icon: 'copy',
          label: 'Duplicate campaign',
          onClick: onOpenDuplicateCampaignModal,
        },
        {
          icon: 'delete',
          label: 'Delete campaign',
          onClick: onOpenDeleteCampaignModal,
        },
      ];
    }

    return [
      {
        icon: 'copy',
        label: 'Duplicate campaign',
        onClick: onOpenDuplicateCampaignModal,
      },
      {
        icon: 'delete',
        label: 'Delete campaign',
        onClick: onOpenDeleteCampaignModal,
      },
    ];
  }

  const clicksData = useMemo(() => {
    if (!campaign?.clicks?.summary || !campaign?.clicks?.summary.length) return;

    const countries: { [key: string]: { country: string; count: number } } = {};
    const cities: { [key: string]: { city: string; count: number; country: string } } = {};

    const groupedCityData = groupBy(campaign?.clicks?.summary, item => item.city);
    const groupedCountryData = groupBy(campaign?.clicks?.summary, item => item.country);

    Object.keys(groupedCountryData).forEach(item => {
      countries[item] = {
        country: item,
        count: groupedCountryData[item].reduce((acc, item) => item.count + acc, 0),
      };
    });

    Object.keys(groupedCityData).forEach(item => {
      cities[item] = {
        city: item,
        country: groupedCityData[item][0].country,
        count: groupedCityData[item].reduce((acc, item) => item.count + acc, 0),
      };
    });

    return { cities, countries };
  }, [campaign?.clicks?.summary]);

  const { cities, countries } = clicksData ?? {};

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <Box height="100vh" overflowY="auto" width="100%" bg="white">
      <Box px="1.5rem" maxW="900px" m="0 auto" p="1rem 0">
        <Flex
          mb="1rem"
          pb="1rem"
          alignItems="center"
          borderBottomWidth="1px"
          justifyContent="space-between"
        >
          <Stack isInline alignItems="center">
            <IconButton
              size="sm"
              rounded="8px"
              variant="ghost"
              aria-label="Back"
              icon="arrow-back"
              borderWidth="1px"
              onClick={() => history.goBack()}
            />
            <Tooltip label={campaign?.name} aria-label={campaign?.name}>
              <Subtitle
                width="250px"
                overflow="hidden"
                whiteSpace="nowrap"
                color="gray.900"
                style={{ textOverflow: 'ellipsis' }}
              >
                {campaign?.name}
              </Subtitle>
            </Tooltip>
            <Badge
              variant="outline"
              textTransform="capitalize"
              variantColor={campaignStatusColors[campaign?.state ?? 'draft']}
            >
              {campaignStateLabel[campaign?.state ?? 'draft']}
            </Badge>
          </Stack>
          <Stack isInline alignItems="center">
            <Box>
              <Tooltip aria-label="Share report" label={<BodyText>Share report</BodyText>}>
                <Button
                  size="sm"
                  variant="outline"
                  aria-label="share"
                  onClick={onOpenShareAnalyticsModal}
                >
                  <Image
                    mr="0.5rem"
                    alt="list-icon"
                    src={`data:image/svg+xml;utf8,${icons['share-2'].toSvg({
                      color: 'gray',
                      width: '1rem',
                      height: '1rem',
                    })}`}
                  />
                  Share
                </Button>
              </Tooltip>
            </Box>
            <Button
              size="sm"
              variantColor="blue"
              onClick={handlCreateCampaignClick}
              isLoading={isCreatingEmailCampaign}
            >
              Create a new campaign
            </Button>

            <TableDropdown<CampaignData>
              data={campaign ?? {}}
              open={onOpenOptionsDropdown}
              isOpen={isOptionsDropdownOpen}
              close={onCloseOptionsDropdown}
              actions={getTableActions(data?.campaign ?? {})}
            />
          </Stack>
        </Flex>
        <EmailAnalytics
          cities={cities}
          reports={reports}
          campaign={campaign}
          countries={countries}
          most_opened_users={most_opened_users}
          most_clicked_users={most_clicked_users}
          onOpenComingSoonModal={onOpenComingSoonModal}
          isExportSuccessToastOpen={isExportSuccessToastOpen}
          onCloseExportSuccessToast={onCloseExportSuccessToast}
          onOpenReportsDownloadModal={onOpenReportsDownloadModal}
        />
      </Box>
      {deleteCampaignModalIsOpen && (
        <ConfirmModal
          title="Delete campaign"
          isLoading={isDeletingCampaign}
          isOpen={deleteCampaignModalIsOpen}
          onClose={onCloseDeleteCampaignModal}
          onConfirm={() => mutateDeleteCampaign(campaign)}
        />
      )}
      {duplicateCampaignModalIsOpen && (
        <ConfirmModal
          title="Duplicate campaign"
          isLoading={loading === 'pending'}
          isOpen={duplicateCampaignModalIsOpen}
          onClose={onCloseDuplicateCampaignModal}
          onConfirm={handleDuplicateCampaign}
        />
      )}
      {isReportsDownloadModalOpen && (
        <ClicksReportDownloadModal
          type="email"
          isLoading={isGeneratingReport}
          isOpen={isReportsDownloadModalOpen}
          onClose={onCloseReportsDownloadModal}
          handleDownload={handleGenerateEmailClickReport}
        />
      )}
      {isShareAnalyticsModalOpen && (
        <ShareCampaignAnalyticsModal
          campaign={campaign ?? {}}
          isOpen={isShareAnalyticsModalOpen}
          onClose={onCloseShareAnalyticsModal}
        >
          <EmailAnalytics
            cities={cities}
            reports={reports}
            isGeneratingImage
            campaign={campaign}
            countries={countries}
            most_opened_users={most_opened_users}
            most_clicked_users={most_clicked_users}
            onOpenComingSoonModal={onOpenComingSoonModal}
            isExportSuccessToastOpen={isExportSuccessToastOpen}
            onCloseExportSuccessToast={onCloseExportSuccessToast}
            onOpenReportsDownloadModal={onOpenReportsDownloadModal}
          />
        </ShareCampaignAnalyticsModal>
      )}
      {isComingSoonModalOpen && (
        <ComingSoonModal isOpen={isComingSoonModalOpen} onClose={onCloseComingSoonModal} />
      )}
      {isNoEmailDomainModalOpen && (
        <InfoModal
          isOpen={isNoEmailDomainModalOpen}
          onClose={onCloseNoEmailDomainModal}
          heading="Authenticate your organization's email domain"
          caption="Authenticate your organization domains and start sending email campaigns with your organisation emails."
          buttonProps={{
            children: 'Authenticate domain',
            onClick: () => history.push('/s/settings/organisation/sending-domains'),
          }}
        />
      )}
    </Box>
  );
};
