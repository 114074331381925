import { useToast } from '@chakra-ui/core';
import {
  selectDataModels,
  selectLists,
  selectListsById,
  selectSegments,
  selectSmartLists,
  selectSmartListsById,
} from 'app/authenticated-app/lists';
import { useQuery as useQueryParams } from 'hooks';
import { getCompanyProfile, listEmailSenderProfiles } from 'app/authenticated-app/settings';
import { EmailSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { ToastBox } from 'app/components';
import { FormikHelpers, FormikState } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client } from 'utils';
import { getCampaignAudience } from '../campaigns.service';
import { CampaignPayload } from '../campaigns.types';
import { CompanyProfilePayload } from 'app/authenticated-app/settings/component/company-profile';

export const useEmailCampaignContent = (options: {
  values: FormikState<CampaignPayload>['values'];
  setFieldValue?: FormikHelpers<Partial<CampaignPayload>>['setFieldValue'];
}) => {
  const { values, setFieldValue } = options;

  const [hasNoEmailType, setHasNoEmailType] = useState(false);

  const toast = useToast();
  const query = useQueryParams();
  const lists: any = useSelector(selectLists);
  const segments = useSelector(selectSegments);
  const data_models = useSelector(selectDataModels);
  const smart_lists: any = useSelector(selectSmartLists);
  const lists_by_id: string[] = useSelector(selectListsById);
  const smart_lists_by_id: string[] = useSelector(selectSmartListsById);

  const recipientListId = query.get('id');
  const recipientListType = query.get('type');

  const listOptions = lists_by_id
    //@ts-ignore
    .map((id: string) => lists[id])
    .map(({ name, id }) => ({ label: name, value: id }));

  const smartListOptions = smart_lists_by_id
    //@ts-ignore
    .map((id: string) => smart_lists[id])
    .map(({ name, id }) => ({ label: name, value: `${id}-smartList` }));

  const allLists = [
    { label: 'Lists', options: listOptions, showBadge: true },
    { label: 'Smart lists', options: smartListOptions, showBadge: true },
  ];

  const { data: emailSenderProfiles } = useQuery<EmailSenderProfilePayload[] | undefined>(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  const { data: companyProfile } = useQuery<CompanyProfilePayload>(
    ['company-profile'],
    getCompanyProfile,
  );

  const senderProfiles = useMemo(() => {
    return emailSenderProfiles?.map(item => ({
      label: `${item.from_email} - ${item.from_name}`,
      value: item.from_email,
    }));
  }, [emailSenderProfiles]);

  const handleGetListCount = async (payload: {
    smart_list_id?: string;
    table_id?: string;
    group_id?: string;
    segment_id?: string;
    audience_type: number;
  }) => {
    try {
      const { audience } = await getCampaignAudience(payload);
      return audience.count;
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message={typeof error === 'string' ? error : error.message} />
        ),
      });
    }
  };

  const handleGetAudience = async (audienceId: string) => {
    try {
      const response = await client(`audience_list/${audienceId}`);
      const { audience_list } = response.data;
      return audience_list;
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message={typeof error === 'string' ? error : error.message} />
        ),
      });
    }
  };

  const handleAudienceChange = async (value?: string) => {
    if (value?.endsWith('-smartList')) {
      value = value.replace('-smartList', '');
      const count = await handleGetListCount({ smart_list_id: value, audience_type: 2 });
      setFieldValue?.('smart_list_id', value);
      setFieldValue?.('campaign_id', value);
      setFieldValue?.('table_id', null);
      setFieldValue?.('import_id', null);
      setFieldValue?.('audience_id', null);
      setFieldValue?.('segment_id', null);
      setFieldValue?.('count', count);
      setFieldValue?.('audience_type', 2);
    } else if (value?.endsWith('-audience')) {
      value = value.replace('-audience', '');
      const count = await handleGetAudience(value);
      setFieldValue?.('audience_id', value);
      setFieldValue?.('campaign_id', value);
      setFieldValue?.('table_id', null);
      setFieldValue?.('import_id', null);
      setFieldValue?.('smart_list_id', null);
      setFieldValue?.('segment_id', null);
      setFieldValue?.('count', count ?? 0);
      setFieldValue?.('audience_type', 1);
    } else if (value?.endsWith('-segment')) {
      value = value.replace('-segment', '');
      const count = await handleGetListCount({ segment_id: value, audience_type: 2 });
      setFieldValue?.('segment_id', value);
      setFieldValue?.('campaign_id', value);
      setFieldValue?.('table_id', null);
      setFieldValue?.('import_id', null);
      setFieldValue?.('smart_list_id', null);
      setFieldValue?.('audience_id', null);
      setFieldValue?.('count', count ?? 0);
      setFieldValue?.('audience_type', 2);
    } else {
      const count = await handleGetListCount({ table_id: value, audience_type: 2 });
      setFieldValue?.('table_id', value);
      setFieldValue?.('campaign_id', value);
      setFieldValue?.('import_id', null);
      setFieldValue?.('smart_list_id', null);
      setFieldValue?.('audience_id', null);
      setFieldValue?.('segment_id', null);
      setFieldValue?.('count', count);
      setFieldValue?.('audience_type', 2);
    }
  };

  const getAudienceValue = () => {
    const { table_id, audience_id, smart_list_id, segment_id } = values;
    if (!!table_id) {
      return table_id;
    }
    if (!!smart_list_id) {
      return `${smart_list_id}-smartList`;
    }
    if (!!audience_id) {
      return `${audience_id}-audience`;
    }
    if (!!segment_id) {
      return `${segment_id}-segment`;
    }
    return '';
  };

  const handleFromChange = (value: string) => {
    const { from_name, reply_to } =
      emailSenderProfiles?.find(item => item.from_email === value) ?? {};
    setFieldValue?.('from_name', from_name);
    setFieldValue?.('sender_id', value);
    setFieldValue?.('reply_to', reply_to);
  };

  const onCloseEmailTypeAlert = () => setHasNoEmailType(false);

  useEffect(() => {
    const getCount = async (payload: {
      smart_list_id?: string;
      table_id?: string;
      group_id?: string;
      segment_id?: string;
      audience_type: number;
    }) => {
      const count = await handleGetListCount(payload);
      setFieldValue?.('count', count);
    };

    const { table_id, audience_id, segment_id, smart_list_id } = values;

    if (table_id || audience_id || segment_id || smart_list_id) {
      getCount({
        audience_type: 2,
        table_id: table_id ?? '',
        segment_id: segment_id ?? '',
        smart_list_id: smart_list_id ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // Effect to run when user is navigating from list
  useEffect(() => {
    if (recipientListId && recipientListType) {
      setFieldValue?.('audience_type', 2);
      setFieldValue?.('campaign_id', recipientListId);
      recipientListType === 'list' && setFieldValue?.('table_id', recipientListId);
      recipientListType === 'smart_list' && setFieldValue?.('smart_list_id', recipientListId);
    }
  }, [recipientListId, recipientListType, setFieldValue]);

  useEffect(() => {
    if (values.table_id) {
      const list = lists[values.table_id];
      const hasNoEmailType = !list?.columns.filter(
        (item: any) => item.type.toLowerCase() === 'email',
      ).length;
      setHasNoEmailType(hasNoEmailType);
    }
    if (values.smart_list_id) {
      const smart_list = smart_lists[values.smart_list_id];
      const list = lists[smart_list.table_id];
      const hasNoEmailType = !list?.columns.filter(
        (item: any) => item.type.toLowerCase() === 'email',
      ).length;
      setHasNoEmailType(hasNoEmailType);
    }
    if (values.segment_id) {
      const segment = segments.find((segment: any) => segment.id === values.segment_id);
      const data_model = data_models.find(item => item.id === segment?.data_model_id);
      const hasNoEmailType = !data_model?.columns.filter(
        (item: any) => item.type.toLowerCase() === 'email',
      ).length;
      setHasNoEmailType(hasNoEmailType);
    }
  }, [
    values.table_id,
    values.smart_list_id,
    values.segment_id,
    data_models,
    lists,
    segments,
    smart_lists,
  ]);

  return {
    senderProfiles,
    hasNoEmailType,
    companyProfile,
    lists: allLists,
    handleFromChange,
    getAudienceValue,
    handleAudienceChange,
    onCloseEmailTypeAlert,
  };
};
