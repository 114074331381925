import React, { useEffect, useState } from 'react';
import { List, SmartList } from '../../../../lists.types';
import { Component as ListLists } from './list-lists';
import { Route, Switch } from 'react-router';
import { CreateList } from './create-list';
import { useDispatch } from 'react-redux';
import { fetchCustomApps, fetchCustomAppsKeys } from 'app/authenticated-app/lists/thunks/resources';
import { useToast } from '@chakra-ui/core';
import { ToastBox } from 'app/components';

interface Props {
  importedData: any;
  lists: List[];
  smart_lists: SmartList[];
  favorite_lists: List[];
  trash_lists: List[];
  favorites: string[];
  openCreateTableModal: Function;
  searchValue: string;
  visualization: string;
  updateList: Function;
  deleteList: Function;
  restoreList: Function;
  deleteListPermanently: Function;
  deleteSmartList: Function;
  updateSmartList: Function;
  markListFavorite: Function;
  unMarkListFavorite: Function;
  selectedTab: string;
  selectTab: Function;
  initial_list: string;
  openNoSubscriptionModal: Function;
  deleteTrashPermanently: Function;
  addList: Function;
  addListFromTemplate: Function;
  handleImport: Function;
  handleImportMapping: Function;
  handleImportNewTable: Function;
  fetchGSheetSpreadSheets: Function;
  fetchGSheetMetadata: Function;
  queueResourceImport: Function;
  queueAppImport: Function;
  fetchResourceSchema: Function;
  fetchAppEndpoints: Function;
  fetchAppEndpointSchema: Function;
  lists_meta: any;
}

export const Component = (props: Props) => {
  const {
    lists,
    importedData,
    addList,
    addListFromTemplate,
    openNoSubscriptionModal,
    handleImport,
    handleImportMapping,
    handleImportNewTable,
    fetchGSheetSpreadSheets,
    fetchGSheetMetadata,
    queueResourceImport,
    queueAppImport,
    fetchResourceSchema,
    fetchAppEndpoints,
    fetchAppEndpointSchema,
  } = props;

  const dispatch = useDispatch();
  const [customApps, setCustomApps] = useState([]);
  const [customAppsKeys, setCustomAppsKeys] = useState([]);
  const toast = useToast();

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      try {
        const customKeys = (await dispatch(fetchCustomAppsKeys())) as any;
        const custom = (await dispatch(fetchCustomApps())) as any;

        if (isMounted) {
          setCustomAppsKeys(customKeys);
          setCustomApps(custom);
        }
      } catch (e) {
        if (isMounted) {
          setCustomAppsKeys([]);
          setCustomApps([]);
        }
        toast({
          position: 'bottom-left',
          render: () => <ToastBox message="Unable to fetch custom apps, please try again" />,
        });
      }
    };

    fetch();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {lists.length < parseInt(process.env.REACT_APP_LIST_LIMIT ?? '5') && (
        <Route exact path="/s/lists/lists/new">
          <CreateList
            {...{
              importedData,
              addList,
              addListFromTemplate,
              openNoSubscriptionModal,
              handleImport,
              handleImportMapping,
              handleImportNewTable,
              fetchGSheetSpreadSheets,
              fetchGSheetMetadata,
              queueResourceImport,
              queueAppImport,
              fetchResourceSchema,
              fetchAppEndpoints,
              fetchAppEndpointSchema,
              customApps,
              customAppsKeys,
            }}
          />
        </Route>
      )}
      <Route path="/s/lists/*">
        <ListLists {...props} />
      </Route>
    </Switch>
  );
};
