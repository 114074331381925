import React, { useState } from 'react';
import { ListFormViewProps } from '.';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  PseudoBox,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/core';
import { BodyText, Button, ToastBox } from 'app/components';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router-dom';

export const FormTemplates: React.FC<Pick<ListFormViewProps, 'form' | 'updateForm'>> = ({
  form,
  updateForm,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { viewId } = useParams<{ viewId: string }>();

  const [formType, setFormType] = useState<string | undefined>();

  const formTypes = [
    {
      type: 'Popup',
      key: 'popup-with-left-image',
      label: 'Popup with left image',
      image: '/images/forms/popup-with-left-image.svg',
    },
    {
      type: 'Popup',
      key: 'popup-with-right-image',
      label: 'Popup with right image',
      image: '/images/forms/popup-with-right-image.svg',
    },
    {
      type: 'Popup',
      key: 'popup-with-no-image',
      label: 'Popup without image',
      image: '/images/forms/popup-without-image.svg',
    },
    {
      type: 'Page',
      key: 'page-with-top-image',
      label: 'Page with top image',
      image: '/images/forms/page-with-top-image.svg',
    },
    {
      type: 'Page',
      key: 'page-with-left-image',
      label: 'Page with left image',
      image: '/images/forms/page-with-left-image.svg',
    },
    {
      type: 'Page',
      key: 'page-with-right-image',
      label: 'Page with right image',
      image: '/images/forms/page-with-right-image.svg',
    },
    {
      type: 'Page',
      key: 'page-with-no-image',
      label: 'Page without image',
      image: '/images/forms/page-without-image.svg',
    },
    {
      type: 'Drawer',
      key: 'drawer-with-image',
      label: 'Drawer with image',
      image: '/images/forms/drawer-1.svg',
    },
    {
      type: 'Drawer',
      key: 'drawer-with-no-image',
      label: 'Drawer without image',
      image: '/images/forms/drawer-2.svg',
    },
  ];

  const updateFormInteractionType = async (interaction_type: string) => {
    try {
      const payload = {
        interaction_type,
      };
      await updateForm(form.id, payload);
      history.push(`/s/lists/view/form/${form.id}/${viewId}/design`);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Unable to update form type, please try again" />
        ),
      });
    }
  };

  const headerRegister: {
    [key: string]: {
      heading: string;
      caption: string;
    };
  } = {
    Page: {
      heading: 'Full page form',
      caption: 'Best used on websites and webpages for surveys and contact forms.',
    },
    Drawer: {
      heading: 'Drawer form',
      caption:
        'Best used for collecting in-app data such as user feedback, feature requests and in-app surveys.',
    },
    Popup: {
      heading: 'Popup form',
      caption: 'Best used for collecting user information on blogs and newsletter.',
    },
  };

  const { caption, heading } = headerRegister[formType ?? ''] ?? {};

  return (
    <Box w="100%" flex={1} overflowY="auto">
      <Stack spacing={0} flexDirection={{ base: 'column', md: 'column', lg: 'row' }}>
        <Stack
          p={8}
          h="100%"
          bg="White"
          w={{ base: '100%', md: '100%', lg: '320px' }}
          maxW={{ base: '100%', md: '100%', lg: '320px' }}
          position={{ base: 'relative', md: 'relative', lg: 'fixed' }}
        >
          <Heading size="md">Choose a form type</Heading>
          <List spacing="2">
            <ListItem
              fontSize="sm"
              cursor="pointer"
              onClick={() => setFormType(undefined)}
              color={formType === undefined ? 'gray.900' : 'gray.500'}
              fontWeight={formType === undefined ? 'semibold' : 'normal'}
            >
              All
            </ListItem>
            <ListItem
              fontSize="sm"
              cursor="pointer"
              onClick={() => setFormType('Page')}
              color={formType === 'Page' ? 'gray.900' : 'gray.500'}
              fontWeight={formType === 'Page' ? 'semibold' : 'normal'}
            >
              Full page
            </ListItem>
            <ListItem
              fontSize="sm"
              cursor="pointer"
              onClick={() => setFormType('Popup')}
              color={formType === 'Popup' ? 'gray.900' : 'gray.500'}
              fontWeight={formType === 'Popup' ? 'semibold' : 'normal'}
            >
              Popup
            </ListItem>
            <ListItem
              fontSize="sm"
              cursor="pointer"
              onClick={() => setFormType('Drawer')}
              color={formType === 'Drawer' ? 'gray.900' : 'gray.500'}
              fontWeight={formType === 'Drawer' ? 'semibold' : 'normal'}
            >
              Drawer
            </ListItem>
          </List>
        </Stack>
        <Stack px={4} pt={8} pb={12} flex={1} mx="auto" spacing={8} maxW="1200px">
          {formType && (
            <Stack spacing={0}>
              <Heading size="md">{heading}</Heading>
              <BodyText color="gray.500">{caption}</BodyText>
            </Stack>
          )}
          <SimpleGrid w="100%" columns={{ base: 1, md: 3 }} spacing={8}>
            {formTypes
              .filter(ft => (!formType ? ft : ft.type === formType))
              .map(t => (
                <TemplateContainer
                  top="0"
                  key={t.key}
                  rounded="8px"
                  cursor="pointer"
                  position="relative"
                  boxShadow="0 4px 15px rgba(0,0,0,.06)"
                  transition="top .3s cubic-bezier(.455,.03,.515,.955) 0s,box-shadow .3s cubic-bezier(.455,.03,.515,.955) 0s"
                  _hover={{
                    top: '-4px',
                    boxShadow: '20px 20px 20px rgba(0,0,0,.15)',
                  }}
                >
                  {form?.interaction_type === t.key && (
                    <Icon
                      top="12px"
                      zIndex={3}
                      size="1rem"
                      right="12px"
                      color="green.500"
                      name="check-circle"
                      position="absolute"
                    />
                  )}
                  <Box bg="white" h="200px" roundedTop="8px" position="relative" overflow="hidden">
                    <Image src={t.image} h="100%" w="100%" />
                    <Flex
                      top="0"
                      w="100%"
                      h="100%"
                      right="0"
                      opacity={0}
                      zIndex={-1}
                      bg="transparent"
                      roundedTop="8px"
                      position="absolute"
                      alignItems="center"
                      justifyContent="center"
                      transition="all .3s ease-out"
                      className="template-item-overlay"
                      onClick={() => updateFormInteractionType(t.key)}
                    >
                      <Button size="sm" onClick={() => updateFormInteractionType(t.key)}>
                        Customize
                      </Button>
                    </Flex>
                  </Box>
                  <Stack bg="white" roundedBottom="8px" borderTopWidth="1px" p="1.5rem 2rem 2.5rem">
                    <BodyText color="gray.500" fontSize="0.75rem" textTransform="uppercase">
                      {t.type}
                    </BodyText>
                    <BodyText fontWeight={500}>{t.label}</BodyText>
                  </Stack>
                </TemplateContainer>
              ))}
          </SimpleGrid>
        </Stack>
      </Stack>
    </Box>
  );
};

const TemplateContainer = styled(PseudoBox)`
  &:hover {
    .template-item-overlay {
      z-index: 1;
      opacity: 1;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;
