import {
  Box,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { PropertySelect } from 'app/authenticated-app/lists/components/grid/property/property-select';
import { PopoverListItem } from 'app/authenticated-app/lists/components/popover-list-item';
import { PropertySelectWrapper } from 'app/authenticated-app/lists/list-view.styles';
import { customizable_properties } from 'app/authenticated-app/lists/list.data';
import { selectEnrichmentSettings } from 'app/authenticated-app/lists/lists.selectors';
import { getColumnCustomizationComponent } from 'app/authenticated-app/lists/lists.utils';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export const FormColumnMenu: React.FC<{
  column: any;
  columns: any;
  updateLabel: any;
  tooltipText?: string;
  columnsByID?: string[];
  buttonTrigger: ReactNode;
  updateCustomization: any;
  deleteColumn(id: string): void;
  updateColumn(id: string, payload: any): void;
  addSelectOption: (column_id: string, value: string) => any;
  updateSelectOption: (column_id: string, option_id: string | number, payload: object) => void;
}> = props => {
  const {
    column,
    columns,
    updateLabel,
    columnsByID,
    tooltipText,
    deleteColumn,
    updateColumn,
    buttonTrigger,
    addSelectOption,
    updateSelectOption,
    updateCustomization,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [stage, setStage] = useState('property');
  const [tempLabel, setTempLabel] = useState('');

  const { isOpen, onClose, onOpen } = useDisclosure();

  const enrichmentSettings = useSelector(selectEnrichmentSettings);

  const CustomizationComponent: React.ReactNode = getColumnCustomizationComponent(
    props?.column?.type,
  );

  const checkColumnIndex = (columnID: string) => {
    return columnsByID?.findIndex((i: string) => i === columnID);
  };

  const handleBlur = (e: any) => {
    if (tempLabel !== column.label) updateLabel(column.uid, tempLabel);
  };

  const handleEnterPressed = (e: any) => {
    if (e.key !== 'Enter') return;
    if (tempLabel === column.label) onClose();
    updateLabel(column.uid, tempLabel);
    onClose();
  };

  const attachEnterPressedListener = () => {
    const input_element = document.getElementById('col-label-input');
    if (!input_element) return;
    input_element.addEventListener('keydown', handleEnterPressed);
  };

  const detachEnterPressedListener = () => {
    const input_element = document.getElementById('col-label-input');
    if (!input_element) return;
    input_element.removeEventListener('keydown', handleEnterPressed);
  };

  const handleTempLabelChange = (event: any) => {
    const value = event.target.value;
    setTempLabel(value);
  };

  const handleTypeChanged = (type: string) => {
    const payload = {
      type,
      label: tempLabel,
    };
    updateColumn(column.uid ?? '', payload);
    setStage('property');
    if (['CREATED AT', 'UPDATED AT', 'DATE'].includes(type)) onClose();
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    attachEnterPressedListener();
    return detachEnterPressedListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tempLabel]);

  useEffect(() => {
    if (column) setTempLabel(column.label);
  }, [column]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      initialFocusRef={inputRef}
    >
      <Tooltip placement="bottom" label={tooltipText ?? ''} aria-label={tooltipText ?? ''}>
        <Box display="inline-block">
          <PopoverTrigger>{buttonTrigger}</PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent
        maxW="189px"
        zIndex={100}
        rounded="5px"
        borderWidth="1px"
        borderColor="rgba(67, 90, 111, 0.1)"
        shadow="rgba(99, 99, 99, 0.2) 0px 10px 10px 0px"
        _focus={{
          outline: 'none',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 10px 10px 0px',
        }}
      >
        <PopoverBody p={0}>
          <PropertySelectWrapper>
            <Box p="7px 10px" width="auto">
              <Input
                isFullWidth
                height={30}
                fontSize={13}
                p="5px 7.5px"
                ref={inputRef}
                variant="filled"
                value={tempLabel}
                onBlur={handleBlur}
                id="col-label-input"
                onChange={handleTempLabelChange}
                isInvalid={tempLabel.length === 0}
                style={{
                  backgroundColor: '#EDF2F7',
                }}
              />
            </Box>
            <Text color="#828282" fontSize="11px" p="5px 10px" textTransform="uppercase">
              Property type
            </Text>
            <PropertySelect
              column={column}
              columnIndex={checkColumnIndex(column.uid)}
              onChange={handleTypeChanged}
              disabled={
                column?.type === 'DND' ||
                // @ts-ignore
                enrichmentSettings['mapping'][column.uid]
              }
            />
            {stage === 'property' && (
              <>
                <hr />
                {customizable_properties.includes(column?.type) && (
                  <PopoverListItem
                    label="Customize field"
                    icon="settings"
                    onClick={() => setStage('customize')}
                  />
                )}

                {deleteColumn && (
                  <PopoverListItem
                    label="Delete field"
                    icon="trash"
                    onClick={() => deleteColumn(column.uid)}
                    disabled={
                      column?.type === 'DND' ||
                      columnsByID?.length === 1 ||
                      checkColumnIndex(column.uid) === 0 ||
                      // @ts-ignore
                      enrichmentSettings['mapping'][column.uid]
                    }
                  />
                )}
              </>
            )}
            {stage === 'customize' && (
              // @ts-ignore
              <CustomizationComponent
                close={onClose}
                column={column}
                columns={columns}
                addSelectOption={addSelectOption}
                updateCustomization={updateCustomization}
                updateSelectOption={(option_id: string, payload: any) =>
                  updateSelectOption(column.uid, option_id, payload)
                }
              />
            )}
          </PropertySelectWrapper>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
