import {
  AttachmentCell,
  AttachmentFieldComponent,
  CheckboxCell,
  CheckboxFieldComponent,
  CheckboxFieldFilterComponent,
  DateCell,
  DateCustomizationMenu,
  DateFieldComponent,
  DNDCell,
  DNDFieldComponent,
  DNDFieldFilterComponent,
  EmailCell,
  EmailFieldComponent,
  InstagramCellComponent,
  LongTextCell,
  LongTextFieldComponent,
  MessengerCellComponent,
  MultiSelectCell,
  MultiSelectFieldComponent,
  NumberCellComponent,
  NumberCustomizationMenu,
  NumberFieldComponent,
  PhoneCell,
  PhoneFieldComponent,
  SelectCell,
  SelectFieldComponent,
  TextCellComponent,
  TextFieldComponent,
  TwitterCellComponent,
  URLCell,
  WhatsappCellComponent,
} from './components/cell-properties';
import { available_properties, list_option_colors, select_option_colors } from './list.data';
import { icons } from 'feather-icons';
import moment from 'moment';
import { ListViewType } from './lists.types';
import { SelectCustomizationMenu } from './components/cell-properties/select/customization';

export const getCellRenderer = (columnType: string) => {
  if (columnType === 'NUMBER') return NumberCellComponent;
  if (columnType === 'MULTI SELECT') return MultiSelectCell;
  if (columnType === 'SELECT') return SelectCell;
  if (columnType === 'EMAIL') return EmailCell;
  if (columnType === 'PHONE NUMBER') return PhoneCell;
  if (columnType === 'URL') return URLCell;
  if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(columnType)) return DateCell;
  if (columnType === 'DND') return DNDCell;
  if (columnType === 'ATTACHMENT') return AttachmentCell;
  if (columnType === 'CHECKBOX') return CheckboxCell;
  if (columnType === 'LONGTEXT') return LongTextCell;
  if (columnType === 'INSTAGRAM') return InstagramCellComponent;
  if (columnType === 'TWITTER') return TwitterCellComponent;
  if (columnType === 'MESSENGER') return MessengerCellComponent;
  if (columnType === 'WHATSAPP') return WhatsappCellComponent;
  return TextCellComponent;
};

export const getFieldRenderer = (columnType: string) => {
  if (columnType === 'TEXT') return TextFieldComponent;
  if (columnType === 'EMAIL') return EmailFieldComponent;
  if (columnType === 'NUMBER') return NumberFieldComponent;
  if (columnType === 'PHONE NUMBER') return PhoneFieldComponent;
  if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(columnType)) return DateFieldComponent;
  if (columnType === 'MULTI SELECT') return MultiSelectFieldComponent;
  if (columnType === 'SELECT') return SelectFieldComponent;
  if (columnType === 'DND') return DNDFieldComponent;
  if (columnType === 'ATTACHMENT') return AttachmentFieldComponent;
  if (columnType === 'CHECKBOX') return CheckboxFieldComponent;
  if (columnType === 'LONGTEXT') return LongTextFieldComponent;
  return TextFieldComponent;
};

export const getFilterFieldRenderer = (columnType: string) => {
  if (columnType === 'TEXT') return TextFieldComponent;
  if (columnType === 'EMAIL') return EmailFieldComponent;
  if (columnType === 'NUMBER') return NumberFieldComponent;
  if (columnType === 'PHONE NUMBER') return PhoneFieldComponent;
  if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(columnType)) return DateFieldComponent;
  if (columnType === 'MULTI SELECT') return MultiSelectFieldComponent;
  if (columnType === 'SELECT') return MultiSelectFieldComponent;
  if (columnType === 'DND') return DNDFieldFilterComponent;
  if (columnType === 'CHECKBOX') return CheckboxFieldFilterComponent;
  return TextFieldComponent;
};

export const getColumnCustomizationComponent = (columnType: string) => {
  if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(columnType)) return DateCustomizationMenu;
  if (columnType === 'NUMBER') return NumberCustomizationMenu;
  if (['MULTI SELECT', 'SELECT'].includes(columnType)) return SelectCustomizationMenu;
};

export const getColumnHeaderIcon = (columnType: string) => {
  // @ts-ignore
  if (available_properties[columnType]) return available_properties[columnType].icon;
  return 'text';
};

export const getRowApiValue = (row: any) => {
  const { id, ...rest } = row;
  let result = { id } as any;
  Object.keys(rest).forEach(item => {
    const columnId = rest[item].columnId;
    if (columnId) {
      result[columnId] = rest[item].value;
    }
  });
  return result;
};

export const generateUpdatedRows = (row_ids: [], rows: {}, column_id_map: {}) => {
  const updated_rows = row_ids.map((i: string) => {
    // @ts-ignore
    const row = rows[i];
    const columns = Object.keys(row.columns).reduce(
      (acc, col_uid) => ({
        ...acc,
        // @ts-ignore
        [column_id_map[col_uid]]: row.columns[col_uid],
      }),
      {},
    );
    const updated_row = {
      ...row,
      columns,
    };
    return updated_row;
  });
  return updated_rows;
};

export const generateFilterPayload = (
  filters: {},
  filters_by_id: any[],
  column_id_map: {},
  columns = {},
) => {
  const payload = filters_by_id.map((i: string) => {
    // @ts-ignore
    const filter = filters[i];
    const columnUID = filter['columnID'];
    // @ts-ignore
    const columnID = filter['column_id'] || column_id_map[columnUID];

    // @ts-ignore
    const columnType = columns?.[columnUID]['type'];

    const filter_item = {
      ...filter,
      columnID,
      column_id: columnID,
      sub_operator: filter['subOperator'] || null,
      conjunction: filter['conjunction'] || null,
    };

    if (['CREATED AT', 'UPDATED AT', 'DATE'].includes(columnType)) {
      const { subOperator } = filter;
      if (subOperator === 'today') filter_item['value'] = { diff: '0', period: 'day' };
      if (subOperator === 'tomorrow') filter_item['value'] = { diff: '+1', period: 'day' };
      if (subOperator === 'yesterday') filter_item['value'] = { diff: '-1', period: 'day' };
      if (subOperator === 'one week ago') filter_item['value'] = { diff: '-7', period: 'day' };
      if (subOperator === 'one week from now') filter_item['value'] = { diff: '+7', period: 'day' };
      if (subOperator === 'one month ago') filter_item['value'] = { diff: '-1', period: 'month' };
      if (subOperator === 'one month from now')
        filter_item['value'] = { diff: '+1', period: 'month' };
      if (subOperator === 'number of days from now') {
        filter_item['value'] = {
          diff: filter_item['value'] ? `+${filter_item['value']}` : '0',
          period: 'day',
        };
      }
    }

    delete filter_item.uid;
    return filter_item;
  });

  return payload;
};

export const generateSortPayload = (
  sorts: {},
  sorts_by_id: [],
  column_id_map: {},
  columns = {},
) => {
  const payload = sorts_by_id.map((i: string) => {
    // @ts-ignore
    const sort = sorts[i];
    const sort_item = {
      ...sort,
      // @ts-ignore
      columnType: columns?.[sort.columnID]['type'],
      // @ts-ignore
      columnID: column_id_map?.[sort.columnID],
      // @ts-ignore
      column_id: column_id_map?.[sort.columnID],
    };
    delete sort_item.uid;
    // delete sort_item.columnID;
    return sort_item;
  });
  return payload;
};

export const generateRandomSelectColor = () => {
  const index = Math.floor(Math.random() * select_option_colors.length);
  return select_option_colors[index].color;
};

export const generateRandomListColor = () => {
  const index = Math.floor(Math.random() * list_option_colors.length);
  return list_option_colors[index];
};

export const generateRandomListIcon = () => {
  const icon_options = Object.keys(icons);
  const index = Math.floor(Math.random() * icon_options.length);
  return icon_options[index];
};

export const parseDate = (raw: string) => {
  let parsed = moment(raw);
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D/MM/YYYY');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D/MM/YY');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D-MM-YYYY');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D-MM-YY');
  if (parsed.isValid()) return parsed;
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D MM YYYY');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'D MM YY');
  return parsed;
};

export const parseTime = (raw: string) => {
  let parsed = moment(raw, 'hh:mm A');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'HH:mm A');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'h:mm A');
  if (parsed.isValid()) return parsed;
  parsed = moment(raw, 'H:mm A');
  return parsed;
};

export const sortListByTime = (a: any, b: any) => {
  if (moment(a.created_datetime).isAfter(moment(b.created_datetime))) return 1;
  else return -1;
};

export const getListResourceIcon = (resource_provider: string) => {
  if (resource_provider === 'google-sheets') return 'g-sheets';
  if (resource_provider === 'mysql') return 'mysql';
  if (resource_provider === 'mssql') return 'mssql';
  if (resource_provider === 'pgsql') return 'postgreSql';
  if (resource_provider === 'shopify') return 'shopify';
  if (resource_provider === 'woo-commerce') return 'woo-commerce';
  if (resource_provider === 'mambu') return 'mambu';
  if (resource_provider === 'magento') return 'magento';
  if (resource_provider === 'typeform') return 'typeform';
  if (resource_provider === 'hubspot') return 'hubspot';
  if (resource_provider === 'custom') return 'list-api';
  return 'list-api';
};

export const getAllowCreateList = (activeSubscription: any, listCount: number) => {
  let allowCreateList: boolean = false;
  if (!activeSubscription?.details?.lists?.lists) allowCreateList = true;
  else if (activeSubscription.details.lists.lists > listCount) allowCreateList = true;

  return allowCreateList;
};

export const wait = (timeout: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};

export const getViewIcon = (viewType: ListViewType) => {
  if (viewType === 'grid') return 'grid3';
  if (viewType === 'form') return 'grid2';
  if (viewType === 'calendar') return 'calendar';
  if (viewType === 'kanban') return 'dataModel';
  return 'grid3';
};

export const calculateEnrichmentCost = (phone: boolean, company: boolean, person: boolean) => {
  let cost: number = 0;
  if (phone) cost += 1;
  if (company || person) cost += 50;
  return cost;
};
