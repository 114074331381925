import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/core';
import { useDisclosure } from '@chakra-ui/core/dist';
import { ListImportStatusIndicator } from 'app/authenticated-app/lists/components/list-import-status-indicator';
import {
  selectListImportStatus,
  selectListDuplicateJobData,
} from 'app/authenticated-app/lists/lists.slice';
import { ListHeadingActionsButton } from 'app/authenticated-app/lists/component/list-view/components/ListHeading/actions-button';
import { Button, ConfirmModal, ToastBox } from 'app/components';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'root';
import usePermission from 'utils/usePermission';
import { selectUserID } from '../../../../../../unauthenticated-app/authentication';
import { TeamMember } from '../../../../../settings/settings.types';
import { selectOrgMembers } from '../../../../../settings/slices';
import { ListFilterMenu } from '../../../../components/filter/filter-menu';
import { ListPropertyMenu } from '../../../../components/list-property-menu';
import { ListSearch } from '../../../../components/search';
import { ListSortMenu } from '../../../../components/sort/sort-menu';
import { selectInitialListId, selectViews } from '../../../../lists.selectors';
import { List, ListView, SmartList } from '../../../../lists.types';
import { ListTitleEditable } from '../ListTitleEditable';
import { useFlags } from 'flagsmith/react';
import { MergeModal } from 'app/authenticated-app/lists/components/merge/merge-modal';
import {
  setListDuplicateJobData,
  toggleAutomationPanel,
} from 'app/authenticated-app/lists/lists.reducer';

type Props = {
  list: List;
  smart_list: SmartList;
  list_syncing: boolean;
  rows_loading: boolean;
  columns: any;
  columns_by_id: [];
  updateColumnArrangement: () => {};
  history: any;
  updateListName: (value: string) => {};
  hideColumn: (columnID: string) => {};
  showColumn: (columnID: string) => {};
  updateFilter: (filter_id: string, payload: object) => {};
  deleteFilter: (filter_id: string, payload: object) => {};
  addFilter: () => {};
  filters: {};
  filters_by_id: [];
  sorts: {};
  sorts_by_id: [];
  addSort: () => {};
  deleteSort: (sort_id: string) => {};
  updateSort: (filter_id: string, payload: object) => {};
  deleteList: (list_id: string) => {};
  createSmartList: Function;
  updateSmartList: Function;
  deleteSmartList: Function;
  selectSmartList: Function;
  selectSmartListID: Function;
  updateSmartListFilters: Function;
  clearFilters: Function;
  is_smart_list: boolean;
  search_query: string;
  updateSearchQuery: Function;
  initial_list: string;
  selected_rows: string[];
  openClearRowsDialog: () => void;
  openDeleteRowsDialog: () => void;
  toggleViewPanel: () => void;
  viewPanelOpen: boolean;
  activeView: string;
  reloadSelectedList: Function;
  updateList: Function;
};

export const ListHeading = (props: Props) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [updateAccessLoading, setUpdateAccessLoading] = useState<boolean>(false);
  const views: ListView[] = useSelector(selectViews);
  const { form_embed_feature } = useFlags(['form_embed_feature']);

  const activeView = views.find((i: ListView) => i.id === props.activeView);

  const dispatch = useDispatch();
  const orgMembers: TeamMember[] = useSelector(selectOrgMembers);
  const creatorMember = orgMembers.find((i: TeamMember) => i.id === props.list.user_id);

  const {
    history,
    list,
    smart_list,
    rows_loading,
    columns,
    columns_by_id,
    updateColumnArrangement,
    list_syncing,
    updateListName,
    hideColumn,
    showColumn,
    addFilter,
    filters,
    filters_by_id,
    updateFilter,
    deleteFilter,
    sorts,
    sorts_by_id,
    addSort,
    deleteSort,
    updateSort,
    deleteList,
    createSmartList,
    updateSmartList,
    deleteSmartList,
    selectSmartList,
    selectSmartListID,
    updateSmartListFilters,
    clearFilters,
    is_smart_list,
    search_query,
    updateSearchQuery,
    selected_rows,
    openClearRowsDialog,
    openDeleteRowsDialog,
    updateList,
  } = props;

  const handleListTitleChange = (value: string) => {
    if (!is_smart_list) updateListName(value);
    else updateSmartList(smart_list.id, { name: value });
  };

  const toast = useToast();

  const handleDeleteList = () => {
    setShowDeleteDialog(false);
    if (is_smart_list) deleteSmartList(smart_list['id']);
    else deleteList(list['id']);
    history.push('/s/lists');
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox
          status="success"
          onClose={onClose}
          message={`${is_smart_list ? smart_list.name : list.name} ${
            is_smart_list ? 'smart list' : 'list'
          } deleted`}
        />
      ),
    });
  };

  const handleUpdateAccessType = async () => {
    setUpdateAccessLoading(true);

    try {
      const payload = {
        access_type: list.access_type === 'public' ? 'private' : 'public',
      };
      updateList(list.id, payload);
      closeUpdateAccessDialog();
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="List access updated successfully!"
          />
        ),
      });
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message="Unable to update list access, please try again"
          />
        ),
      });
    }
    setUpdateAccessLoading(false);
  };

  const navToList = (list_id: string) => {
    history.push(`/s/lists/view/${list_id}`);
    clearFilters();
  };

  const initialListID = useSelector(selectInitialListId);
  const listImportStatus = useSelector(selectListImportStatus);
  const isInitial = initialListID === list['id'];

  const formLink: string = `${process.env.REACT_APP_FORMS_URL}/${activeView?.form.id}`;
  const { onCopy } = useClipboard(formLink);

  const copyLink = () => {
    // @ts-ignore
    onCopy();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" onClose={onClose} message="Form link copied successfully" />
      ),
    });
  };

  const embedForm = () => {
    window.open(`/s/lists/embed/${list['id']}/${props.activeView}`, '_blank');
  };

  const hanldeStartMergeFlow = (head_list_id: string) => {
    const base_list_id = list.id;
    history.push(`/s/lists/merge/${base_list_id}/${head_list_id}`);
  };

  function handleOpenDuplicateCheckPanel() {
    dispatch(
      toggleAutomationPanel({
        automationPanelOpen: true,
        automationPanelActiveSection: 'duplicate-check',
      }),
    );
  }

  const {
    auth: { profile },
    teams: { organisationMembers },
  } = useSelector((state: RootState) => state);

  const permissions = profile && profile.permissions;
  const allowFilter = usePermission('list.filter', permissions || []);
  const allowDeleteRow = usePermission('list.row.delete', permissions || []);
  const id = profile && profile.id;
  const userRole = organisationMembers.find((members: any) => members.profile_id === id);
  const roleName = userRole && userRole.name;

  const userID = useSelector(selectUserID);
  const listDuplicateJobData = useSelector(selectListDuplicateJobData);

  const {
    isOpen: showUpdateAccessDialog,
    onClose: closeUpdateAccessDialog,
    onOpen: openUpdateAccessDialog,
  } = useDisclosure(false);

  const {
    isOpen: showMergeModal,
    onClose: closeMergeModal,
    onOpen: openMergeModal,
  } = useDisclosure(false);

  return (
    <>
      <Box className="list-header">
        <Box className="title-box">
          <Stack isInline alignItems="center">
            <Tooltip placement="bottom" aria-label="Back to lists" label="Back to lists">
              <Box
                title="Back to lists"
                className="icon-bg back"
                marginRight="5px !important"
                onClick={() => {
                  dispatch(
                    setListDuplicateJobData({
                      list_id: undefined,
                      status: undefined,
                      object_id: undefined,
                    }),
                  );
                  history.push('/s/lists/lists');
                }}
              >
                <Icon name="arrow-back" />
              </Box>
            </Tooltip>

            {roleName !== 'Guest' && (
              <Tooltip aria-label="Views" label="Views" placement="bottom">
                <Button
                  size="sm"
                  color="white"
                  variant="ghost"
                  //@ts-ignore
                  leftIcon="hamburger"
                  onClick={props.toggleViewPanel}
                  _hover={{
                    backgroundColor: 'rgba(0,0,0,0.3)',
                  }}
                >
                  Views
                </Button>
              </Tooltip>
            )}
          </Stack>

          <ListTitleEditable
            isEditing={false}
            isInitial={isInitial}
            value={is_smart_list ? smart_list['name'] : list['name']}
            onChange={handleListTitleChange}
          />

          <Box style={{ flexShrink: '0', marginTop: '5px' }}>
            {activeView?.type === 'form' && (
              <>
                <Button
                  size="xs"
                  mr="5px"
                  color="white"
                  fontWeight="400"
                  leftIcon="view"
                  variant="ghost"
                  onClick={() => window.open(formLink, '_blank')}
                  _hover={{
                    bg: '#313337',
                  }}
                >
                  Open Form
                </Button>
                <Button
                  size="xs"
                  color="white"
                  fontWeight="400"
                  leftIcon="copy"
                  variant="ghost"
                  onClick={copyLink}
                  _hover={{
                    bg: '#313337',
                  }}
                >
                  Copy shareable link
                </Button>
                {form_embed_feature.enabled && (
                  <Button
                    size="xs"
                    color="white"
                    variant="ghost"
                    fontWeight="400"
                    onClick={embedForm}
                    leftIcon="external-link"
                    _hover={{
                      bg: '#313337',
                    }}
                  >
                    Embed form
                  </Button>
                )}
              </>
            )}
          </Box>
          {isInitial && (
            <Box
              fontSize="9px"
              color="#ffffff"
              textAlign="center"
              alignSelf="center"
              backgroundColor="rgba(255, 255, 255, 0.2)"
              borderRadius="5px"
              padding="1px 8px"
              mt="2px"
              width="fit-content"
              marginX="auto"
              letterSpacing="1.5px"
              textTransform="uppercase"
              border="1px solid rgba(0, 0, 0, 0.5)"
            >
              primary
            </Box>
          )}
          {(list.access_type === 'private' || list.user_id === userID) && (
            <Box style={{ flexShrink: 0 }}>
              <Menu>
                <MenuButton
                  as={Button}
                  // @ts-ignore
                  rightIcon="chevron-down"
                  size="xs"
                  variant="outline"
                  variantColor="green"
                  mr="0.5rem"
                  ml="0.5rem"
                  fontSize="9px"
                  letterSpacing="1px"
                  paddingTop="2px"
                  height="1rem"
                >
                  {list.access_type.toUpperCase()}
                </MenuButton>
                <MenuList minW="150px" fontSize="14px">
                  <MenuItem onClick={openUpdateAccessDialog}>
                    {list.access_type === 'private' ? 'Make this public' : 'Make this private'}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
          <Box display="flex" flexDirection="row" alignItems="center" ml="0.5rem">
            {creatorMember && (
              <Tooltip
                aria-label="creator"
                label={`${creatorMember.first_name} ${creatorMember.last_name} (${creatorMember.email})`}
              >
                <Box
                  mr="0.5rem"
                  display="flex"
                  alignItems="center"
                  style={{
                    justifyContent: 'center !important',
                  }}
                >
                  <Tooltip
                    label={`${creatorMember.first_name} ${creatorMember.last_name}`}
                    aria-label={`${creatorMember.first_name} ${creatorMember.last_name}`}
                  >
                    <Avatar
                      justifyContent="center !important"
                      src={creatorMember.image}
                      name={`${creatorMember.first_name} ${creatorMember.last_name}`}
                      size="2xs"
                      style={{
                        justifyContent: 'center  !important',
                      }}
                    />
                  </Tooltip>
                </Box>
              </Tooltip>
            )}
            <Box
              fontSize="12px"
              fontWeight="400"
              color="#fff"
              mt="3px"
              display="flex"
              flexDirection="row"
              width="max-content"
            >
              {'Created on ' +
                moment(
                  is_smart_list ? props.smart_list.created_datetime : props.list.created_datetime,
                ).format('MMMM Do YYYY, h:mm A')}
            </Box>
          </Box>
        </Box>
        <div className="toolbar">
          {activeView?.type !== 'form' && (
            <>
              <Box display="flex" flex="1">
                <ListSearch search_query={search_query} updateSearchQuery={updateSearchQuery} />

                {!!selected_rows.length && (
                  <>
                    <Box display="flex" mr="0.5rem" alignItems="center">
                      <Button
                        aria-label="clear rows"
                        // @ts-ignore
                        mr="10px"
                        size="xs"
                        variant="ghost"
                        variantColor="red"
                        onClick={openClearRowsDialog}
                        isDisabled={!allowDeleteRow}
                      >
                        Clear {selected_rows.length} row(s)
                      </Button>
                      <Button
                        aria-label="delete rows"
                        // @ts-ignore
                        size="xs"
                        variant="solid"
                        variantColor="red"
                        onClick={openDeleteRowsDialog}
                        isDisabled={!allowDeleteRow}
                      >
                        Delete {selected_rows.length} row(s)
                      </Button>
                    </Box>
                    <Divider orientation="vertical" color="#E0E0E0" height="15px" width="1.5px" />
                  </>
                )}
                {listImportStatus && (
                  <Box mr="0.5rem">
                    <ListImportStatusIndicator status={listImportStatus} />
                  </Box>
                )}
                {is_smart_list && (
                  <Breadcrumb
                    marginX="7px"
                    color="#757575"
                    fontSize="11px"
                    spacing="8px"
                    whiteSpace="nowrap"
                    marginTop="6px"
                    separator={<Icon color="gray.500" name="chevron-right" />}
                  >
                    <BreadcrumbItem>
                      <BreadcrumbLink onClick={() => navToList(list.id)}>
                        {list.name}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink>{smart_list.name}</BreadcrumbLink>
                    </BreadcrumbItem>
                  </Breadcrumb>
                )}
                {is_smart_list && (
                  <Box
                    className="list-badge"
                    style={{
                      fontSize: '8px',
                      letterSpacing: 1,
                      height: 'max-content',
                      whiteSpace: 'nowrap',
                      marginTop: '6px',
                    }}
                  >
                    SMART LIST
                  </Box>
                )}
              </Box>
              <Box display="flex">
                {!rows_loading && !list_syncing && (
                  <Button
                    mt="3px"
                    size="xs"
                    mr="0.5rem"
                    ml="0.5rem"
                    variant="ghost"
                    fontWeight="400"
                    leftIcon="repeat"
                    aria-label="reload"
                    onClick={() => props.reloadSelectedList()}
                    isDisabled={roleName !== 'Guest' ? false : true}
                    _hover={{
                      backgroundColor: '#eeeeee',
                    }}
                  >
                    Reload
                  </Button>
                )}
                {list_syncing && (
                  <Box fontSize="11px" mr="0.5rem" marginTop="3px">
                    Syncing...
                  </Box>
                )}
                {rows_loading && (
                  <Box fontSize="11px" mr="0.5rem" marginTop="3px">
                    Loading...
                  </Box>
                )}
                {!is_smart_list && (
                  <>
                    <Button
                      mt="3px"
                      size="xs"
                      mr="0.5rem"
                      variant="ghost"
                      fontWeight="400"
                      //@ts-ignore
                      leftIcon="copy"
                      onClick={handleOpenDuplicateCheckPanel}
                      _hover={{
                        backgroundColor: '#eeeeee',
                      }}
                      isDisabled={
                        roleName === 'Guest' ||
                        (!!listDuplicateJobData.list_id && listDuplicateJobData.list_id !== list.id)
                      }
                    >
                      Duplicate check
                    </Button>
                    <Button
                      mt="3px"
                      size="xs"
                      mr="0.5rem"
                      variant="ghost"
                      fontWeight="400"
                      //@ts-ignore
                      leftIcon="merge"
                      onClick={openMergeModal}
                      _hover={{
                        backgroundColor: '#eeeeee',
                      }}
                      isDisabled={roleName === 'Guest'}
                    >
                      Merge
                    </Button>
                  </>
                )}
                <ListSortMenu
                  columns={columns}
                  columns_by_id={columns_by_id}
                  sorts={sorts}
                  sorts_by_id={sorts_by_id}
                  addSort={addSort}
                  deleteSort={deleteSort}
                  updateSort={updateSort}
                />
              </Box>
              <Divider orientation="vertical" color="#E0E0E0" height="15px" width="1.5px" />
              {allowFilter && (
                <>
                  <ListFilterMenu
                    columns={columns}
                    columns_by_id={columns_by_id}
                    addFilter={addFilter}
                    updateFilter={updateFilter}
                    filters={filters}
                    filters_by_id={filters_by_id}
                    deleteFilter={deleteFilter}
                    createSmartList={createSmartList}
                    selectSmartListID={selectSmartListID}
                    selectSmartList={selectSmartList}
                    updateSmartListFilters={updateSmartListFilters}
                    smart_list={smart_list}
                    is_smart_list={is_smart_list}
                  />
                  <Divider orientation="vertical" color="#E0E0E0" height="15px" width="1.5px" />
                </>
              )}
              <ListPropertyMenu
                columns={columns}
                columns_by_id={columns_by_id}
                updateColumnArrangement={updateColumnArrangement}
                hideColumn={hideColumn}
                showColumn={showColumn}
              />
              <Divider
                mx="0.2rem"
                width="1.5px"
                height="15px"
                color="#E0E0E0"
                orientation="vertical"
              />
              <ListHeadingActionsButton
                type={is_smart_list ? 'smart_list' : 'list'}
                id={is_smart_list ? smart_list.id : list.id}
              />
            </>
          )}
        </div>
      </Box>
      {showDeleteDialog && (
        <ConfirmModal
          isOpen={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          title={is_smart_list ? 'Delete smart list' : 'Delete list'}
          description={`We'll move this ${
            smart_list ? 'smart list' : 'list'
          } to your trash for 30days`}
          onConfirm={handleDeleteList}
        />
      )}
      {showUpdateAccessDialog && (
        <ConfirmModal
          isOpen={showUpdateAccessDialog}
          onClose={closeUpdateAccessDialog}
          title="Update list access"
          hidePrompt
          description={
            list.access_type === 'private'
              ? 'This will make this list accessible to everyone in this organisation.'
              : 'This will make this list accessible to only you and no one else will be able to view it.'
          }
          onConfirm={handleUpdateAccessType}
          isLoading={updateAccessLoading}
        />
      )}
      {showMergeModal && (
        <MergeModal
          base_list={list.id}
          isOpen={showMergeModal}
          onClose={closeMergeModal}
          onSubmit={hanldeStartMergeFlow}
        />
      )}
    </>
  );
};
