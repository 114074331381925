import { Box } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '../../../../../../../components';
import _ from 'lodash';
import { string } from 'yup';

interface Props {
  formik: any;
  setActiveField: Function;
}

export const RedirectFieldSection = (props: Props) => {
  const { values, handleBlur, handleFocus, setFieldValue } = props.formik;
  const { setActiveField } = props;

  const [tempRedirectURL, setTempRedirectURL] = useState<string>(values.redirect_url || '');
  const [validationError, setValidationError] = useState<string>('');

  const handleURLUpdate = (updatedURL: string) => {
    setFieldValue('redirect_url', updatedURL);
  };

  const debouncedURLHandler = useCallback(
    _.debounce((updatedURL: string) => handleURLUpdate(updatedURL), 1000),
    [],
  );

  const validateURL = (url: string): boolean => {
    try {
      string().url('Redirect URL must be a valid URL').validateSync(url);
      setValidationError('');
    } catch (e: any) {
      setValidationError(e.message);
      return false;
    }
    return true;
  };

  const handleURLChange = (e: any) => {
    const updatedURL = e.target.value;
    setTempRedirectURL(updatedURL);
    const isURLValid = validateURL(updatedURL);
    if (isURLValid) {
      debouncedURLHandler(updatedURL);
    }
  };

  useEffect(() => {
    if (values.redirect_url) {
      setTempRedirectURL(values.redirect_url);
    }
  }, [values.redirect_url]);

  return (
    <Box mb={!!validationError ? '2.5rem' : '1.5rem'}>
      <Box display="flex" alignItems="center">
        <input
          style={{ height: 16, width: 16 }}
          checked={values.redirect_after_submit}
          type={'checkbox'}
          name="redirect_after_submit"
          onChange={(e: any) => setFieldValue('redirect_after_submit', e.target.checked)}
          onBlur={(e: any) => {
            handleBlur(e);
            setActiveField(null);
          }}
          onFocus={handleFocus}
        />
        <Box ml="15px" display="flex" flexDirection="row" alignItems="center">
          <Box fontSize="12px" color="#777777">
            Redirect to URL after the form is submitted
          </Box>
        </Box>
      </Box>

      {values.redirect_after_submit && (
        <Box display="flex" width="100%" height="3rem" marginTop="1rem">
          <Input
            size="sm"
            name="redirect_url"
            value={tempRedirectURL}
            onChange={handleURLChange}
            onBlur={(e: any) => {
              handleBlur(e);
              setActiveField(null);
            }}
            onFocus={handleFocus}
            isInvalid={!!validationError}
            errorMessage={validationError || ''}
            placeholder="https://example.com"
          />
        </Box>
      )}
    </Box>
  );
};
