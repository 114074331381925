import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Spinner,
  useToast,
  useDisclosure,
  Button,
} from '@chakra-ui/core';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { selectViews, selectViewsLoading } from 'app/authenticated-app/lists/lists.selectors';
import { ListView, ListViewType } from 'app/authenticated-app/lists/lists.types';
import { getViewIcon } from 'app/authenticated-app/lists/lists.utils';
import { ToastBox, Menu, MenuItem, ConfirmModal } from 'app/components';
import { ListViewPanelWrapper as Wrapper } from './index.styles';
import { RootState } from 'root';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

interface ViewItemProps {
  id?: string;
  name: string;
  type: ListViewType;
  active: boolean;
  onClick: () => void;
  updateView?: Function;
  deleteView?: Function;
}

const ViewItem = (props: ViewItemProps) => {
  const [editActive, setEditActive] = useState<boolean>(false);
  const [tempLabel, setTempLabel] = useState<string>('');
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const inputRef = useRef(null);

  const {
    isOpen: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useDisclosure();

  useEffect(() => {
    setTempLabel(props.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenameClicked = (e: Event) => {
    e.stopPropagation();
    setEditActive(true);
  };

  const handleOutsideClick = (e: any) => {
    if (!editActive) return;
    // @ts-ignore
    if (inputRef.current.contains(e.target)) return;
    if (tempLabel.length === 0) return;
    // @ts-ignore
    props.updateView(props.id, { name: tempLabel });
    setEditActive(false);
  };

  const attachOutsideClickListener = () => {
    document.addEventListener('mousedown', handleOutsideClick);
  };

  const detachOutsideClickListener = () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };

  useEffect(() => {
    attachOutsideClickListener();
    return detachOutsideClickListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const {
    auth: { profile },
    teams: { organisationMembers },
  } = useSelector((state: RootState) => state);
  const id = profile && profile.id;
  const userRole = organisationMembers.find((org: any) => org.profile_id === id);
  const roleName: any = userRole && userRole.name;

  const handleClick = () => {};

  const truthyRole = () => roleName === 'Admin' || roleName === 'Member';

  return (
    <>
      <Box
        className={`view-item ${props.active && 'active'}`}
        onClick={truthyRole() ? props.onClick : handleClick}
      >
        <Box display="flex" alignItems="center">
          <Icon name={getViewIcon(props.type)} mr="10px" />
          {editActive ? (
            <Input
              ref={inputRef}
              size="sm"
              value={tempLabel}
              onChange={(e: any) => setTempLabel(e.target.value)}
              onKeyPress={(e: any) => {
                if (e.key !== 'Enter') return;
                if (tempLabel.length === 0) return;
                // @ts-ignore
                props.updateView(props.id, { name: tempLabel });
                setEditActive(false);
              }}
              isInvalid={tempLabel.length === 0}
              fontSize="12px"
              padding="0 8px"
              height="25px"
              borderRadius="4px"
              autoFocus
            />
          ) : (
            <Box
              onDoubleClick={(e: any) => {
                e.stopPropagation();
                if (props.type === 'grid') return;
                if (roleName === 'Guest') return;
                setEditActive(true);
              }}
            >
              {tempLabel}
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" onClick={(e: any) => e.stopPropagation()}>
          {props.type !== 'grid' && truthyRole() && (
            <Menu
              {...{
                renderItem: option => <MenuItem {...option} />,
                menuButtonProps: {
                  as: IconButton,
                  ariaLabel: 'menu',
                  icon: 'chevron-down',
                  fontSize: '14px',
                  borderRadius: '12px',
                  padding: '0px',
                  size: 'xs',
                },
                menuListProps: {
                  minWidth: 150,
                },
                options: [
                  { children: 'Rename view', key: 0, onClick: handleRenameClicked },
                  { children: 'Delete view', key: 1, color: '#BF2600', onClick: openDeleteDialog },
                ],
              }}
            />
          )}
        </Box>
      </Box>

      <ConfirmModal
        title="Delete View"
        isLoading={deleteLoading}
        onConfirm={async () => {
          setDeleteLoading(true);
          // @ts-ignore
          await props.deleteView(props?.id);
          setDeleteLoading(false);
          closeDeleteDialog();
        }}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
      />
    </>
  );
};

interface Props {
  isPanelOpen: boolean;
  createView: Function;
  updateView: Function;
  deleteView: Function;
}

export const ListViewPanel = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [createViewLoading, setCreateViewLoading] = useState<boolean>(false);

  const viewsLoading: boolean = useSelector(selectViewsLoading);
  const views: ListView[] = useSelector(selectViews);

  const filteredViews = views.filter((i: ListView) =>
    i.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const toast = useToast();

  useEffect(() => {
    setSearchQuery('');
  }, [props.isPanelOpen, views]);

  const createView = async (viewType: ListViewType) => {
    setCreateViewLoading(true);

    try {
      const view: ListView = await props.createView({
        type: viewType,
        name: `${capitalize(viewType)} 1`,
      });

      history.push(`/s/lists/view/form/${list_id}/${view.id}/templates`);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="List view created" />
        ),
      });
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message="Unable to create view, please try again"
          />
        ),
      });
    }
    setCreateViewLoading(false);
  };

  const deleteView = async (viewID: ListViewType) => {
    try {
      await props.deleteView(viewID);
      history.push(`/s/lists/view/grid/${list_id}`);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="View deleted successfully" />
        ),
      });
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message="Unable to delete view, please try again"
          />
        ),
      });
    }
  };

  const variants = {
    open: { width: 300, display: 'inherit', opacity: 1 },
    closed: { width: 0, display: 'none', opacity: 0 },
  };

  const {
    auth: { profile },
    teams: { organisationMembers },
  } = useSelector((state: RootState) => state);
  const id = profile && profile.id;
  const userRole = organisationMembers.find((org: any) => org.profile_id === id);
  const roleName = userRole && userRole.name;

  const match = useRouteMatch();
  const history = useHistory();
  const { id: list_id } = useParams<{ id: string }>();

  return (
    <AnimatePresence>
      {props.isPanelOpen && (
        <Wrapper
          animate={props.isPanelOpen ? 'open' : 'closed'}
          variants={variants}
          transition={{ duration: 0.15 }}
          exit={{ width: 0, opacity: 0 }}
        >
          <Box className="views-list-section">
            <InputGroup marginBottom="20px" marginTop="10px">
              <InputLeftElement children={<Icon name="search" size="15px" />} />
              <Input
                variant="flushed"
                placeholder="Find a view"
                size="sm"
                paddingY="8px"
                fontSize="14px"
                autoFocus
                value={searchQuery}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              />
            </InputGroup>

            <Box className="view-list">
              <ViewItem
                type="grid"
                name="Grid view"
                active={match.url.includes('grid')}
                onClick={() => history.push(`/s/lists/view/grid/${list_id}`)}
              />
              {filteredViews.map((i: ListView, index: number) => (
                <ViewItem
                  key={index}
                  {...i}
                  deleteView={deleteView}
                  updateView={props.updateView}
                  active={match.url.includes(i.id)}
                  onClick={() =>
                    history.push(
                      `/s/lists/view/form/${list_id}/${i.id}/${
                        i.form.interaction_type ? 'design' : 'templates'
                      }`,
                    )
                  }
                />
              ))}
            </Box>
          </Box>

          {viewsLoading && (
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
              margin="auto"
            />
          )}

          <Box className="create-option-list">
            <Box
              marginBottom="15px"
              fontSize="12px"
              paddingX="10px"
              color="#858C94"
              display="flex"
              alignItems="center"
            >
              <Box mr="1rem">Create a...</Box>
              {(viewsLoading || createViewLoading) && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xs"
                />
              )}
            </Box>
            <Button
              variant="ghost"
              className="option-item"
              onClick={() => createView('form')}
              isDisabled={roleName === 'Guest'}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Icon name={getViewIcon('form')} mr="10px" />
                <Box>Form view</Box>
              </Box>
              <Icon name="small-add" />
            </Button>
          </Box>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
