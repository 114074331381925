import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import * as Sentry from '@sentry/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import { createBrowserHistory, createHashHistory } from 'history';
import React from 'react';
import { defaults } from 'react-chartjs-2';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { initAmplitude, isElectron } from 'utils';
import App from './App';
import { GlobalStyles, ResellerSettingsProvider, simpuTheme } from './app/components';
import { configureAppStore, preloadedState } from './root';
import * as serviceWorker from './serviceWorker';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { AxiosInterceptor } from 'app/components/AxiosInterceptor';

initAmplitude();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

defaults.global.defaultFontFamily = 'Averta';
defaults.global.defaultFontSize = 14;
defaults.global.defaultFontColor = '#747aa5';

const queryClient = new QueryClient();

const store = configureAppStore(preloadedState);

const history = isElectron() ? createHashHistory() : createBrowserHistory();

ReactDOM.render(
  <FlagsmithProvider
    options={{
      enableLogs: false,
      cacheFlags: false,
      environmentID: process.env.REACT_APP_FLAGSMITH_ID ?? '',
    }}
    flagsmith={flagsmith}
  >
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools position="top-left" initialIsOpen={false} />
        <AxiosInterceptor>
          <Router history={history}>
            <ResellerSettingsProvider>
              <ThemeProvider theme={simpuTheme}>
                <CSSReset />
                <GlobalStyles />
                <App />
              </ThemeProvider>
            </ResellerSettingsProvider>
          </Router>
        </AxiosInterceptor>
      </QueryClientProvider>
    </Provider>
  </FlagsmithProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
