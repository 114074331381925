import { useToast } from '@chakra-ui/core';
import Axios from 'axios';
import { useFlagsmith } from 'flagsmith/react';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { loadState } from 'utils';
import { identify, track } from 'utils/segment';
import { ToastBox } from '../../../../components';
import { RegisterFormInitialValuesProps } from '../../components';
import { register, saveUser } from '../../service';
import { RegisterComponent } from './Register.component';

const connector = connect(null, { saveUser });

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = RouteComponentProps & PropsFromRedux;

function Register(props: Props) {
  const toast = useToast();
  const flagsmith = useFlagsmith();
  const { history, saveUser } = props;
  const [loading, setLoading] = React.useState(false);

  const BASE_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/'
      : process.env.REACT_APP_DEMO_URL;

  async function handleRegister(values: RegisterFormInitialValuesProps) {
    setLoading(true);
    //TODO: change verify link to app.simpu.co when going live
    let payload = { ...values, link: `${BASE_URL}verify/{{token}}` };
    const localStorageData = loadState();
    // check to see if the person is coming from an invite link
    if (
      !isEmpty(localStorageData) &&
      (localStorageData.team_id || localStorageData.organisation_id) &&
      localStorageData.inviteToken
    ) {
      const { team_id, inviteToken, organisation_id } = localStorageData;
      payload = { ...payload, team_id, organisation_id, inviteToken };
    }
    try {
      const data = await register(payload);

      const hubspotData: { [key: string]: string } = {
        email: payload.email,
        phone: payload.phone,
        lastname: payload.last_name,
        firstname: payload.first_name,
        company: payload.organisation_name,
      };

      const hubspotPayload = {
        submittedAt: Date.now(),
        fields: Object.keys(hubspotData).map(key => ({
          name: key,
          objectTypeId: '0-1',
          value: hubspotData[key],
        })),
      };
      await Axios.post(
        `${process.env.REACT_APP_HUBSPOT_FORM_URL}/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_FORM_ID}`,
        hubspotPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      saveUser(data);

      identify(data.user?.id ?? '', {
        ...data.user,
        app: 'marketing',
      });

      flagsmith.identify(data.user?.id, {
        ...data,
        app: 'marketing',
      });

      track('Signed Up', { ...data });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'signup',
      });

      history.push('/s/lists/lists');

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  return (
    <RegisterComponent
      {...props}
      isLoading={loading}
      onSubmit={handleRegister}
      showOrganizationName={!loadState()?.inviteToken}
    />
  );
}

export const RegisterContainer = connector(Register);
