import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Icon } from '@chakra-ui/core';
import { Column, ListForm } from 'app/authenticated-app/lists/lists.types';
import React from 'react';
import { NavLink, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { FormDesign } from './design';
import { ListFormWrapper as Wrapper } from './index.styles';
import { FormPublish } from './publish';
import { FormTemplates } from './template';

export interface ListFormViewProps {
  form: ListForm;
  updateColumn: any;
  deleteColumn: any;
  createColumn: any;
  updateForm: Function;
  updateColumnLabel: any;
  columns_by_id: string[];
  addSelectOption: Function;
  updateSelectOption: Function;
  updateColumnCustomization: any;
  columns: { [key: string]: Column };
  column_id_map: { [key: string]: string };
}

export const ListFormView = (props: ListFormViewProps) => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <Wrapper>
        <Flex
          h="40px"
          px="1rem"
          bg="white"
          minH="40px"
          roundedTop="4px"
          alignItems="center"
          justifyContent="center"
          borderBottomWidth="1px"
        >
          <Breadcrumb
            fontSize="xs"
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem isCurrentPage={location.pathname === `${match.url}/templates`}>
              <BreadcrumbLink
                //@ts-ignore
                as={NavLink}
                textTransform="uppercase"
                to={`${match.url}/templates`}
                _focus={{ boxShadow: 'none' }}
                _hover={{ color: 'gray.900' }}
                color={location.pathname === `${match.url}/templates` ? 'gray.900' : 'gray.500'}
              >
                Choose a template
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={location.pathname === `${match.url}/design`}>
              <BreadcrumbLink
                //@ts-ignore
                as={NavLink}
                textTransform="uppercase"
                to={`${match.url}/design`}
                _focus={{ boxShadow: 'none' }}
                _hover={{ color: 'gray.900' }}
                color={location.pathname === `${match.url}/design` ? 'gray.900' : 'gray.500'}
              >
                Customize
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={location.pathname === `${match.url}/publish`}>
              <BreadcrumbLink
                //@ts-ignore
                as={NavLink}
                textTransform="uppercase"
                to={`${match.url}/publish`}
                _focus={{ boxShadow: 'none' }}
                _hover={{ color: 'gray.900' }}
                color={location.pathname === `${match.url}/publish` ? 'gray.900' : 'gray.500'}
              >
                Publish
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Switch>
          <Route
            path={`${match.path}/templates`}
            render={() => <FormTemplates form={props.form} updateForm={props.updateForm} />}
          />
          <Route
            path={`${match.path}/design`}
            render={() => (
              <FormDesign
                form={props.form}
                columns={props.columns}
                updateForm={props.updateForm}
                updateColumn={props.updateColumn}
                deleteColumn={props.deleteColumn}
                createColumn={props.createColumn}
                columns_by_id={props.columns_by_id}
                addSelectOption={props.addSelectOption}
                updateColumnLabel={props.updateColumnLabel}
                updateSelectOption={props.updateSelectOption}
                updateColumnCustomization={props.updateColumnCustomization}
              />
            )}
          />
          <Route path={`${match.path}/publish`} render={() => <FormPublish form={props.form} />} />
        </Switch>
      </Wrapper>
    </>
  );
};
