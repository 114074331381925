import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const useFlag = (slug: string) => {
  const organization_id = useSelector(selectOrganisationID);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

  const checkFlag = async () => {
    try {
      const response = await Axios.get(
        `${process.env.NEXT_PUBLIC_FEATURE_FLAG_API}api/flags/check`,
        {
          params: {
            slug,
            organization: organization_id,
          },
        },
      );
      return response.data.isEnabled;
    } catch (error) {
      console.log(error);
    }
  };

  const { data: isEnabled } = useQuery(['flag', slug], checkFlag, {
    enabled: !!(slug && isProduction),
  });

  if (!isProduction) {
    return true;
  }

  return isEnabled;
};
