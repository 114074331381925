import { Badge, Flex, Heading, PseudoBox, Stack, Tooltip, useDisclosure } from '@chakra-ui/core';
import { useFlag } from 'hooks/use-flag';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { getZonedTime, numberWithCommas } from '../../../../../utils';
import { ConfirmModal, SmallText, TableDropdown, XSmallText } from '../../../../components';
import { CampaignData } from '../campaigns.types';
import { campaignStateLabel, campaignStatusColors } from '../campaigns.utils';
import { CampaignContentProps } from './campaign-content';

type CampaignListItemProps = {
  isLoading?: boolean;
  campaign: CampaignData;
} & Pick<
  CampaignContentProps,
  'isDeletingCampaign' | 'onDeleteCampaign' | 'onDuplicateCampaign' | 'isDuplicatingCampaign'
>;

export function CampaignListItem(props: CampaignListItemProps) {
  const {
    campaign,
    isLoading,
    isDeletingCampaign,
    onDeleteCampaign,
    onDuplicateCampaign,
    isDuplicatingCampaign,
  } = props;

  const history = useHistory();
  const isNewEmailCampaignUIEnabled = useFlag('new_email_campaign_ui');

  const {
    isOpen: deleteCampaignModalIsOpen,
    onOpen: onOpenDeleteCampaignModal,
    onClose: onCloseDeleteCampaignModal,
  } = useDisclosure();
  const {
    isOpen: duplicateCampaignModalIsOpen,
    onOpen: onOpenDuplicateCampaignModal,
    onClose: onCloseDuplicateCampaignModal,
  } = useDisclosure();

  function handleClick() {
    if (campaign.type === 'email') {
      if (isNewEmailCampaignUIEnabled) {
        if (
          campaign.state === 'pristine' ||
          !campaign.sender_id ||
          !campaign.table_id ||
          !campaign.smart_list_id
        ) {
          history.push(`/s/marketing/email-campaigns/new/${campaign.id}/audience`);
        } else if (campaign.state === 'draft') {
          history.push(`/s/marketing/email-campaigns/new/${campaign.id}/design`);
        } else {
          history.push(`/s/marketing/email-campaigns/${campaign.id}/analytics`);
        }
      } else {
        if (campaign.state === 'draft' || campaign.state === 'pristine') {
          history.push(`/s/marketing/email-campaigns/${campaign.id}`);
        } else {
          history.push(`/s/marketing/email-campaigns/${campaign.id}/analytics`);
        }
      }
    } else {
      history.push(`/s/marketing/sms-campaigns/${campaign.id}`);
    }
  }

  return (
    <>
      <PseudoBox
        cursor="pointer"
        _hover={{ bg: '#f9f9f9' }}
        onClick={!isLoading ? handleClick : () => {}}
      >
        <Stack
          isInline
          pt="1rem"
          spacing="1rem"
          flexWrap="wrap"
          alignItems="center"
          borderTopWidth="1px"
        >
          <Stack
            pl="1rem"
            spacing="0.2rem"
            marginBottom="1rem"
            width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
          >
            <Heading fontSize="1rem" color="blue.500" textTransform="capitalize">
              {campaign.name}
            </Heading>
            <SmallText color="#595e8a">
              {campaign.created_datetime &&
                getZonedTime(campaign.created_datetime, 'dd MMM yyyy, hh:mm:a')}
            </SmallText>
            <XSmallText color="#4f4f4f">
              Audience Nos: {numberWithCommas(campaign?.audience?.count)}
            </XSmallText>
          </Stack>
          <Flex
            marginBottom="1rem"
            justifyContent="flex-end"
            width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
          >
            <Badge fontWeight="500" textAlign="center" rounded="100px" variantColor="blue">
              {campaign.type}
            </Badge>
          </Flex>
          {campaign.state && (
            <Flex
              marginBottom="1rem"
              justifyContent="flex-end"
              width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
            >
              {campaign.state_description ? (
                <Tooltip
                  placement="bottom"
                  textTransform="capitalize"
                  label={campaign.state_description}
                  aria-label={campaign.state_description ?? ''}
                >
                  <Badge
                    fontWeight="500"
                    rounded="100px"
                    variantColor={campaignStatusColors[campaign.state]}
                  >
                    {campaignStateLabel[campaign.state]}
                  </Badge>
                </Tooltip>
              ) : (
                <Badge
                  fontWeight="500"
                  rounded="100px"
                  variantColor={campaignStatusColors[campaign.state]}
                >
                  {campaignStateLabel[campaign.state]}
                </Badge>
              )}
            </Flex>
          )}
          <Flex marginBottom="1rem" justifyContent="flex-end">
            <TableDropdown<CampaignData>
              data={campaign}
              actions={[
                {
                  icon: 'copy',
                  label: 'Duplicate campaign',
                  onClick: onOpenDuplicateCampaignModal,
                },
                {
                  icon: 'delete',
                  label: 'Delete campaign',
                  onClick: onOpenDeleteCampaignModal,
                },
              ]}
            />
          </Flex>
        </Stack>
      </PseudoBox>

      {deleteCampaignModalIsOpen && (
        <ConfirmModal
          title="Delete campaign"
          isLoading={isDeletingCampaign}
          isOpen={deleteCampaignModalIsOpen}
          onClose={onCloseDeleteCampaignModal}
          onConfirm={() => onDeleteCampaign?.(campaign)}
        />
      )}
      {duplicateCampaignModalIsOpen && (
        <ConfirmModal
          title="Duplicate campaign"
          isLoading={isDuplicatingCampaign}
          isOpen={duplicateCampaignModalIsOpen}
          onClose={onCloseDuplicateCampaignModal}
          onConfirm={() => onDuplicateCampaign?.(campaign)}
        />
      )}
    </>
  );
}
