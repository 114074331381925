import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/core';
import { select_option_colors } from 'app/authenticated-app/lists/list.data';
import { BodyText, ToastBox } from 'app/components';
import React, { ChangeEvent, useState } from 'react';

export const SelectCustomizationMenu = (props: any) => {
  const toast = useToast();
  const column = props.column;
  const options = column.options.filter((option: any) => !option.isDeleted && !option.is_deleted);

  const [isSaving, setIsSaving] = useState(false);

  const handleAddSelectOption = async () => {
    try {
      setIsSaving(true);
      await props.addSelectOption(column.uid, 'Option', '#0f0f0f1a');
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast({
        render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
      });
    }
  };

  const handleUpdateSelectOption = async (id: string, payload: any) => {
    try {
      setIsSaving(true);
      await props.updateSelectOption(id, payload);
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast({
        render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
      });
    }
  };

  const handleDeleteSelectOption = async (id: string) => {
    try {
      setIsSaving(true);
      await props.updateSelectOption(id, {
        is_deleted: true,
      });
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toast({
        render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
      });
    }
  };

  return (
    <>
      <Stack pb="4" spacing="0">
        <Stack borderBottomWidth="1px">
          <Stack isInline alignItems="center">
            <Box className="property-type-label" marginTop="10px">
              OPTIONS
            </Box>
            {isSaving && (
              <Spinner
                size="sm"
                speed="0.9s"
                thickness="2px"
                color="blue.500"
                emptyColor="gray.200"
              />
            )}
          </Stack>
          <Button
            size="sm"
            rounded="none"
            fontWeight={400}
            variant="ghost"
            leftIcon="small-add"
            justifyContent="flex-start"
            onClick={handleAddSelectOption}
          >
            Add option
          </Button>
        </Stack>
        <Stack spacing="0">
          {options.map((i: any) => (
            <Option
              {...i}
              key={i.id}
              deleteSelectOption={handleDeleteSelectOption}
              updateSelectOption={handleUpdateSelectOption}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

const Option = (props: any) => {
  const [name, setName] = useState(props.name);
  const [color, setColor] = useState(props.color);

  const handleUpdateName = () => {
    props.updateSelectOption(props.id, { name, color });
  };

  const handleUpdateColor = (c: string) => {
    setColor(c);
    props.updateSelectOption(props.id, { name, color: c });
  };

  return (
    <Stack
      px="2"
      py="1"
      isInline
      alignItems="center"
      borderBottomWidth="1px"
      justifyContent="space-between"
    >
      <Box>
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <IconButton isRound size="xs" bg={color} aria-label="color" icon="chevron-down" />
          </PopoverTrigger>
          <PopoverContent
            maxW="150px"
            boxShadow="none"
            zIndex={1000000000}
            _focus={{
              boxShadow: 'none',
              outline: 'none',
            }}
          >
            <Box fontSize="10px" fontWeight="600" color="#8c8c8c" paddingX="8px" paddingY="5px">
              COLORS
            </Box>
            {select_option_colors.map((c: any, index: number) => (
              <PseudoBox
                p="1"
                display="flex"
                key={`${index}`}
                alignItems="center"
                borderBottomWidth="1px"
                justifyContent="space-between"
                onClick={() => handleUpdateColor(c.color)}
                _hover={{
                  bg: 'gray.50',
                }}
              >
                <Stack flex={1} isInline alignItems="center">
                  <Box
                    w="15px"
                    h="15px"
                    bg={c.color}
                    rounded="sm"
                    border="1px solid"
                    borderColor={c.color}
                  />
                  <BodyText>{c.label}</BodyText>
                </Stack>
                {color === c.color && <Icon name="check" size="12px" />}
              </PseudoBox>
            ))}
          </PopoverContent>
        </Popover>
      </Box>
      <Input
        flex={1}
        size="sm"
        value={name}
        onBlur={handleUpdateName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleUpdateName();
          }
        }}
      />
      <IconButton
        isRound
        size="xs"
        color="#777777"
        variant="ghost"
        icon="small-close"
        aria-label="delete option"
        _focus={{
          boxShadow: 'none',
        }}
        onClick={() => props.deleteSelectOption(props.id)}
      />
    </Stack>
  );
};
