import { AppThunk } from 'root';
import { setViewsData, setViewsLoading } from '../lists.reducer';
import { client } from 'utils';
import { sendAmplitudeData } from 'utils/amplitude';

export const fetchViews =
  (listID: string): AppThunk =>
  async dispatch => {
    dispatch(setViewsLoading(true));

    try {
      const response = await client(`/lists/list/${listID}/view`, {
        method: 'GET',
      });
      const data: any = {};
      const by_id: string[] = [];

      response.data.forEach((i: any) => {
        data[i.id] = i;
        by_id.push(i.id);
      });

      dispatch(setViewsData({ data, by_id, meta: null }));
      dispatch(setViewsLoading(false));
    } catch (e) {
      dispatch(setViewsLoading(false));
      return null;
    }
  };

export const createView =
  (payload: { type: 'form' | 'grid'; name: string }): AppThunk =>
  async (dispatch, getState) => {
    const {
      lists: {
        selected_list,
        views: { data, by_id },
      },
    } = getState();

    sendAmplitudeData(`deleteView(${payload.type})`);

    const response = await client(`lists/list/view`, {
      method: 'POST',
      data: {
        ...payload,
        list_id: selected_list,
      },
    });

    const updated_data = {
      ...data,
      [response.data.id]: response.data,
    };
    const updated_by_id = [...by_id, response.data.id];

    dispatch(setViewsData({ data: updated_data, by_id: updated_by_id, meta: null }));
    return response.data;
  };

export const updateView =
  (viewID: string, payload: any): AppThunk =>
  async (dispatch, getState) => {
    const {
      lists: {
        views: { data, by_id },
      },
    } = getState();

    // @ts-ignore
    const view = data[viewID];
    sendAmplitudeData(`deleteView(${view.type})`);

    const response = await client(`lists/list/view/${viewID}`, {
      method: 'PATCH',
      data: payload,
    });

    const updated_data = {
      ...data,
      // @ts-ignore
      [viewID]: response.data,
    };

    dispatch(setViewsData({ data: updated_data, by_id, meta: null }));
    return response.data;
  };

export const deleteView =
  (viewID: string): AppThunk =>
  async (dispatch, getState) => {
    const {
      lists: {
        views: { data, by_id },
      },
    } = getState();
    // @ts-ignore
    const view = data[viewID];
    sendAmplitudeData(`deleteView(${view.type})`);

    const response = await client(`lists/list/view/${viewID}`, {
      method: 'DELETE',
    });

    const updated_by_id: string[] = by_id.filter((i: string) => i !== viewID);
    const updated_data = {};

    updated_by_id.forEach((i: string) => {
      // @ts-ignore
      updated_data[i] = data[i];
    });

    dispatch(setViewsData({ data: updated_data, by_id: updated_by_id, meta: null }));
    return response.data;
  };

export const updateForm =
  (formID: string, payload: any): AppThunk =>
  async (dispatch, getState) => {
    sendAmplitudeData('updateForm');

    const {
      lists: {
        views: { data, by_id },
      },
    } = getState();

    const response = await client(`lists/form/${formID}`, {
      method: 'PATCH',
      data: payload,
    });

    const viewID: string = response.data.list_view_id;
    const updated_data = {
      ...data,
      // @ts-ignore
      [viewID]: { ...data[viewID], form: response.data },
    };

    dispatch(setViewsData({ data: updated_data, by_id, meta: null }));
    return response.data;
  };
