import { Button, Icon, ModalBody, ModalCloseButton, Stack } from '@chakra-ui/core';
import {
  BodyText,
  ModalContainer,
  ModalContainerOptions,
  SmallSubtitle,
  Subtitle,
} from 'app/components';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ListLimitModal: React.FC<ModalContainerOptions> = props => {
  const { isOpen, onClose } = props;

  const { push } = useHistory();

  const confirm = () => {
    onClose?.();
    push('/s/lists/lists');
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} size="sm">
      <ModalCloseButton size="sm" />
      <ModalBody py="8">
        <Stack spacing="6">
          <Subtitle textAlign="center">
            You have hit your lists limit of {process.env.REACT_APP_LIST_LIMIT}
          </Subtitle>
          <Stack spacing="4">
            <BodyText fontSize="md" color="gray.700">
              Don't Panic! Here's what you can do:
            </BodyText>
            <Stack spacing="3">
              <Stack isInline>
                <Icon size="20px" name="trash" color="gray.600" />
                <Stack spacing="0">
                  <SmallSubtitle fontSize="sm">Delete lists</SmallSubtitle>
                  <BodyText>Delete lists that are not used anymore</BodyText>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Button size="sm" w="100%" variantColor="blue" onClick={confirm}>
                Delete unused lists
              </Button>
              <Button size="sm" w="100%" variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
