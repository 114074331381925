import {
  Box,
  Code,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useClipboard,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { ListFormViewProps } from '.';
import { BodyText, Button, SmallSubtitle, ToastBox } from 'app/components';

export const FormPublish: React.FC<Pick<ListFormViewProps, 'form'>> = ({ form }) => {
  const toast = useToast();

  const interactionTypeIsPage = form?.interaction_type?.includes('page');
  const interactionTypeIsPopup = form?.interaction_type?.includes('popup');
  const interactionTypeIsDrawer = form?.interaction_type?.includes('drawer');

  const pageTitle = `${interactionTypeIsPage ? 'Share' : 'Publish'} your form`;

  const formPageUrl = `${process.env.REACT_APP_FORMS_URL}/${form?.id}`;

  const popupCodeSnippet = `
  <script>
    var s = document.createElement("script");
    s.src = "${process.env.REACT_APP_WIDGETS_URL}/widgets/v1/form-popup-widget.js";
    s.async = true;
    document.body.appendChild(s);
    s.onload = function () {
      var widget = window.SimpuFormWidgets.init({
        form_id: "${form?.id}",
      });
      widget.render();
      document.querySelector("#open")
      .addEventListener("click", () => {
        widget.open();
      });
    };
  </script>`;

  const drawerCodeSnippet = `
  <script>
    var s = document.createElement("script");
    s.src = "${process.env.REACT_APP_WIDGETS_URL}/widgets/v1/form-drawer-widget.js";
    s.async = true;
    document.body.appendChild(s);
    s.onload = function () {
      var widget = window.SimpuFormWidgets.init({
        form_id: "${form?.id}",
      });
      widget.render();
      document.querySelector("#open")
      .addEventListener("click", () => {
        widget.open();
      });
    };
  </script>`;

  const { hasCopied: hasCopiedFormPageUrl, onCopy: onCopyFormPageUrl } = useClipboard(formPageUrl);

  const { hasCopied: hasCopiedPopupCodeSnippet, onCopy: onCopyPopupCodeSnippet } =
    useClipboard(popupCodeSnippet);

  const { hasCopied: hasCopiedDrawerCodeSnippet, onCopy: onCopyDrawerCodeSnippet } =
    useClipboard(drawerCodeSnippet);

  const handleCopyFormPageUrl = () => {
    onCopyFormPageUrl?.();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" onClose={onClose} message="Form link copied successfully" />
      ),
    });
  };

  const handleCopyPopupCodeSnippet = () => {
    onCopyPopupCodeSnippet?.();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" onClose={onClose} message="Code snippet copied successfully" />
      ),
    });
  };

  const handleCopyDrawerCodeSnippet = () => {
    onCopyDrawerCodeSnippet?.();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" onClose={onClose} message="Code snippet copied successfully" />
      ),
    });
  };

  const handleViewFormPage = () => {
    window.open(formPageUrl, '_blank');
  };

  return (
    <Box w="100%" height="100vh" overflowY="auto" bg="white">
      <Stack
        px={4}
        py={8}
        w="640px"
        mx="auto"
        spacing={8}
        maxW="640px"
        height="100%"
        alignItems="center"
      >
        <Heading size="md" textAlign="center">
          {pageTitle}
        </Heading>

        {interactionTypeIsPage && (
          <Stack w="100%" spacing={8}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="url"
                placeholder="Form url"
                isReadOnly
                defaultValue={formPageUrl}
              />
              <InputRightElement width="4.5rem">
                <Button variantColor="green" size="sm" onClick={handleCopyFormPageUrl}>
                  {hasCopiedFormPageUrl ? 'Copied!' : 'Copy'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <BodyText textAlign="center">OR</BodyText>
            <Button width="100%" variant="solid" leftIcon="view" onClick={handleViewFormPage}>
              View your form
            </Button>
          </Stack>
        )}

        {interactionTypeIsPopup && (
          <Stack
            width="100%"
            style={{ gap: '2rem' }}
            flexDirection={['column', 'column', 'row', 'row']}
          >
            <Stack width={['100%', '100%', '50%', '50%']}>
              <SmallSubtitle>Code</SmallSubtitle>
              <BodyText color="gray.500">{`Install the following Javascript snippet  before the closing </body> tag of the page of your website where you'll want the form to be displayed`}</BodyText>
            </Stack>
            <Box rounded="md" bg="gray.50" width={['100%', '100%', '50%', '50%']}>
              <Box p={4}>
                <Code>{popupCodeSnippet}</Code>
              </Box>
              <Box p={4} borderTopWidth="1px">
                <Button
                  size="sm"
                  width="100%"
                  variantColor="green"
                  onClick={handleCopyPopupCodeSnippet}
                >
                  {hasCopiedPopupCodeSnippet ? 'Copied!' : 'Copy'}
                </Button>
              </Box>
            </Box>
          </Stack>
        )}

        {interactionTypeIsDrawer && (
          <Stack width="100%" isInline style={{ gap: '2rem' }}>
            <Stack width="50%">
              <SmallSubtitle>Code</SmallSubtitle>
              <BodyText color="gray.500">{`Install the following Javascript snippet  before the closing </body> tag of the page of your website where you'll want the form to be displayed`}</BodyText>
            </Stack>
            <Box rounded="md" bg="white" width="50%">
              <Box p={4}>
                <Code>{drawerCodeSnippet}</Code>
              </Box>
              <Box p={4} borderTopWidth="1px">
                <Button
                  size="sm"
                  width="100%"
                  variantColor="green"
                  onClick={handleCopyDrawerCodeSnippet}
                >
                  {hasCopiedDrawerCodeSnippet ? 'Copied!' : 'Copy'}
                </Button>
              </Box>
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
